import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export function ViewAllItemsTooltip(props) {
    return (
        <Tooltip title={props.title} sx={{ pt: 2.5 }}>
            <IconButton onClick={props.onClick}>
                <ShoppingBasketIcon />
            </IconButton>
        </Tooltip>
    );
}

export function BackTooltip(props) {
    return (
        <Tooltip title='Back' sx={props.sx}>
            <IconButton onClick={props.onClick}>
                <ArrowBackIosNewIcon />
            </IconButton>
        </Tooltip >
    );
}