import React, { Component } from 'react';
import { allergies_and_abstentions } from '../../utils/Allergies';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import '../../styles/allergiesTable.css';


export class AllergiesTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allergiesAndAbstentions: { 'allergies': [], 'abstentions': [] },
            value: props.value
        }
    }

    // value is the tag name of the checkbox item,
    // e is the event target space 
    // and isAllergy defines if it's an allergy or abstention checkbox
    checkboxChange = (value, e, isAllergy) => {
        let checkbox = e.target;
        const isCheckboxChecked = checkbox.type === 'checkbox' ? checkbox.checked : checkbox.value;

        // copy the state dictionary to not directly modify the state
        const updateState = { ...this.state.allergiesAndAbstentions };
        let allergyList = updateState.allergies;
        let abstentionList = updateState.abstentions;

        // if checkbox value is true, and it is an allergy the abstention checkbox should directly also be checked
        if (isCheckboxChecked) {
            if (isAllergy) {
                if (!allergyList.includes(value)) allergyList.push(value);
            }
            if (!abstentionList.includes(value)) abstentionList.push(value);
        } else {
            if (isAllergy)
                updateState.allergies = allergyList.filter((e) => value !== e);
            else
                updateState.abstentions = abstentionList.filter((e) => value !== e);
        }
        this.setState({ 'allergiesAndAbstentions': updateState }); // update state again and rerender page
        this.props.handleChange('allergiesAndAbstentions', updateState, () => { console.log(this.props.values) });
    }

    render() {
        const { values, t } = this.props;

        // choose if german translation should be called from allergies list
        var { displayGermanDisorders } = false;
        if (i18next.language === 'de' || i18next.language === 'de-DE') displayGermanDisorders = true;

        return (
            <div className='shadow-z-1'>
                <table className='responsive-table responsive-table-input-matrix'>
                    <tbody>
                        <tr>
                            <th>&nbsp;</th>
                            <th>{t('allergens')}</th>
                            <th>{t('abstentions')}</th>
                        </tr>
                        {allergies_and_abstentions.map(({ tag, allergy_name, abstention_name, deTranslation }, index) => {
                            return (
                                <tr key={index}>

                                    <td data-th='' style={{ marginBottom: 15, marginTop: 15 }}>
                                        {displayGermanDisorders ? deTranslation : tag}
                                    </td>
                                    <td data-th={t('allergen')}>
                                        <label style={{ cursor: "pointer" }}>
                                            <input type='checkbox'
                                                id={`allergy-checkbox-${index}`}
                                                name={allergy_name}
                                                checked={values.allergiesAndAbstentions.allergies.includes(tag)}
                                                onChange={(e) => this.checkboxChange(tag, e, true)}
                                                style={{ width: 15, height: 15 }}
                                            />
                                        </label>
                                    </td>
                                    <td data-th={t('abstention')}>
                                        <label style={{ cursor: "pointer" }}>
                                            <input type='checkbox'
                                                id={`abstentions-checkbox-${index}`}
                                                name={abstention_name}
                                                checked={values.allergiesAndAbstentions.abstentions.includes(tag)}
                                                onChange={(e) => this.checkboxChange(tag, e, false)}
                                                style={{ width: 15, height: 15 }}
                                            />
                                        </label>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div >
        );
    }
}

export default withTranslation()(AllergiesTable);
