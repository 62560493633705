import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';

import SurveyInfoPage from '../../pages/SurveyInfoPage';

export default function DataFetchingStarted(props) {
    const { t } = useTranslation();
    return (
        <SurveyInfoPage
            title={t('first_step_completed')}
            content={[
                <>
                    <Typography component="h1" variant="body1" color="inherit" align="center" sx={{ pb: 1 }}>
                        <Trans i18nKey="datafetching_started" />
                    </Typography>
                    <Grid container justifyContent="space-around" sx={{ pt: 2, pb: 2 }}>
                        <Button
                            variant="contained"
                            color="inherit"
                            size="large"
                            aria-label="large button group"
                            onClick={props.nextStep}
                        >
                            {t('button_next')}
                        </Button>
                    </Grid>
                </>,
            ]}
        />
    );
}
