import React, { useState } from 'react';
import { Button } from '@mui/material';
import DashboardPage from '../../pages/DashboardPage';
import ShoppingHistoryPage from '../../pages/ShoppingHistoryPage';
import { useTranslation } from 'react-i18next';
import NutritionAnalysis from '../../pages/NutritionAnalysis';
import SpendingAnalysis from '../../pages/SpendingAnalysis';

export default function Analysis() {
    const { t } = useTranslation();
    const [showMainAnalysisPage, setShowMainAnalysisPage] = useState(true);
    const [showSpendingAnalysis, setShowSpendingAnalysis] = useState(false);
    const [showNutritionAnalysis, setShowNutritionAnalysis] = useState(false);
    const [showShoppingHistory, setShowShoppingHistory] = useState(false);

    let user = JSON.parse(localStorage.getItem('user'));
    const userid = user.external_id;

    const handleClick = (click) => {
        if (click === 'spending') {
            setShowMainAnalysisPage(false);
            setShowShoppingHistory(false);
            setShowSpendingAnalysis(true);
        }
        if (click === 'nutrition') {
            setShowMainAnalysisPage(false);
            setShowShoppingHistory(false);
            setShowNutritionAnalysis(true);
        }
        if (click === 'shopping') {
            setShowMainAnalysisPage(false);
            setShowNutritionAnalysis(false);
            setShowShoppingHistory(true);
        }
    };

    return (
        <DashboardPage
            content={
                showMainAnalysisPage ? (
                    <>
                        <Button fullWidth variant="contained" sx={{ mt: 3 }} onClick={() => handleClick('spending')}>
                            {t('view_spending_analysis')}
                        </Button>
                        <Button fullWidth variant="contained" sx={{ mt: 2 }} onClick={() => handleClick('nutrition')}>
                            {t('view_nutrition_analysis')}
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2 }}
                            onClick={() => handleClick('shopping')}
                        >
                            {t('view_shopping_history')}
                        </Button>
                    </>
                ) : (
                    (showSpendingAnalysis && (
                        <SpendingAnalysis
                            userid={userid}
                            analysisType="Spending"
                            pageTitle={t('spending_analysis')}
                            handleBack={() => {
                                setShowSpendingAnalysis(false);
                                setShowMainAnalysisPage(true);
                            }}
                        />
                    )) ||
                    (showNutritionAnalysis && (
                        <NutritionAnalysis
                            userid={userid}
                            analysisType="Nutrition"
                            pageTitle={t('nutrition_analysis')}
                            handleBack={() => {
                                setShowSpendingAnalysis(false);
                                setShowMainAnalysisPage(true);
                            }}
                        />
                    )) ||
                    (showShoppingHistory && (
                        <ShoppingHistoryPage
                            pageTitle={t('shopping_history')}
                            handleBack={() => {
                                setShowShoppingHistory(false);
                                setShowMainAnalysisPage(true);
                            }}
                        />
                    ))
                )
            }
        />
    );
}
