export const allergies_and_abstentions = [
    {
        tag: 'Eggs',
        allergy_name: 'allergyCheckboxEggs',
        abstention_name: 'abstainCheckboxEggs',
        deTranslation: 'Eier',
    },
    {
        tag: 'SesameSeeds',
        allergy_name: 'allergyCheckboxSesame',
        abstention_name: 'abstainCheckboxSesame',
        deTranslation: 'Sesam Samen',
    },
    {
        tag: 'Gluten',
        allergy_name: 'allergyCheckboxGluten',
        abstention_name: 'abstainCheckboxGluten',
        deTranslation: 'Gluten',
    },
    {
        tag: 'Soy',
        allergy_name: 'allergyCheckboxSoy',
        abstention_name: 'abstainCheckboxSoy',
        deTranslation: 'Soja',
    },
    {
        tag: 'Nuts',
        allergy_name: 'allergyCheckboxNuts',
        abstention_name: 'abstainCheckboxNuts',
        deTranslation: 'Schalenfrüchte',
    },
    {
        tag: 'Peanuts',
        allergy_name: 'allergyCheckboxPeanuts',
        abstention_name: 'abstainCheckboxPeanuts',
        deTranslation: 'Erdnüsse',
    },
    {
        tag: 'Sulphites',
        allergy_name: 'allergyCheckboxSulphites',
        abstention_name: 'abstainCheckboxSulphites',
        deTranslation: 'Sulfite',
    },
    {
        tag: 'Mustards',
        allergy_name: 'allergyCheckboxMustards',
        abstention_name: 'abstainCheckboxMustards',
        deTranslation: 'Senf',
    },
    {
        tag: 'Cellery',
        allergy_name: 'allergyCheckboxCellery',
        abstention_name: 'abstainCheckboxCellery',
        deTranslation: 'Sellerie',
    },
    {
        tag: 'Fish',
        allergy_name: 'allergyCheckboxFish',
        abstention_name: 'abstainCheckboxFish',
        deTranslation: 'Fisch',
    },
    {
        tag: 'Lupin',
        allergy_name: 'allergyCheckboxLupin',
        abstention_name: 'abstainCheckboxLupin',
        deTranslation: 'Lupinen',
    },
    {
        tag: 'Crustaceans',
        allergy_name: 'allergyCheckboxCrustaceans',
        abstention_name: 'abstainCheckboxCrustaceans',
        deTranslation: 'Krustentiere',
    },
    {
        tag: 'Molluscs',
        allergy_name: 'allergyCheckboxMolluscs',
        abstention_name: 'abstainCheckboxMolluscs',
        deTranslation: 'Weichtiere',
    },
    {
        tag: 'Milk',
        allergy_name: 'allergyCheckboxMilk',
        abstention_name: 'abstainCheckboxMilk',
        deTranslation: 'Milch',
    }
];