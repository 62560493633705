import React, { useState, useEffect } from 'react';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import CircularLoading from '../Loading/CircularLoading';
import { userService } from '../../services/User/userService';
import BetterProductsTable from '../Tables/BetterProductsTable';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { InfoAlert } from '../Alert/Alerts';

// TODO: Implement swr
export default function BetterProducts(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [betterProducts, setBetterProducts] = useState(null);
    const [showInfo, setShowInfo] = useState(false);

    useEffect(() => {
        if (props.gtin !== 1) {
            userService
                .getBetterProductsData(props.gtin)
                .then((receivedData) => {
                    if (receivedData !== null && receivedData !== undefined) {
                        let tenProducts = receivedData.slice(0, 10);
                        setBetterProducts(tenProducts);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else setLoading(false);
        // disabling the "React Hook useEffect has a missing dependency error" comment
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loading ? (
                <CircularLoading />
            ) : (
                <>
                    {betterProducts ? (
                        <>
                            <Grid container justifyContent="center">
                                <Typography
                                    component="h2"
                                    variant="h6"
                                    gutterBottom
                                    sx={{ pt: 1.5, textAlign: 'center' }}
                                    onClick={() => setShowInfo(!showInfo)}
                                >
                                    {t('better_products')}
                                    <InfoOutlinedIcon style={{ marginLeft: 2 }} fontSize="small" />
                                </Typography>
                            </Grid>
                            <BetterProductsTable data={betterProducts} />
                            <InfoAlert
                                open={showInfo}
                                onClose={() => setShowInfo(false)}
                                text={t('info_better_products')}
                            />
                        </>
                    ) : props.suggestion.dietcoach_minor_category === 'Vegetables' ? (
                        <Card sx={{ bgcolor: '#45574b', mt: 2 }}>
                            <CardContent>
                                <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                    {t('buy_more_vegetables')}
                                </Typography>
                            </CardContent>
                        </Card>
                    ) : props.suggestion.dietcoach_minor_category === 'Fruits' ? (
                        <Card sx={{ bgcolor: '#45574b', mt: 2 }}>
                            <CardContent>
                                <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                    {t('buy_more_fruits')}
                                </Typography>
                            </CardContent>
                        </Card>
                    ) : (
                        <Card sx={{ bgcolor: '#45574b', mt: 2 }}>
                            <CardContent>
                                <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                    {t('no_better_products')}
                                </Typography>
                            </CardContent>
                        </Card>
                    )}
                </>
            )}
        </>
    );
}
