import React, { Component } from 'react'
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import SurveyPage from '../../pages/SurveyPage';
import { SurveyButtonGroup } from '../../utils/commonComponents';
import { validateSurveyFields } from '../../utils/formValidator';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { ErrorAlert } from "../Alert/Alerts";

class SportsQuestion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value,
            missingChoiceError: false
        }
        this.options = [
            { value: '4+', label: i18next.t('survey_select_usersports_4') },
            { value: '2-3', label: i18next.t('survey_select_usersports_3') },
            { value: '1', label: i18next.t('survey_select_usersports_2') },
            { value: '<1', label: i18next.t('survey_select_usersports_1') },
            { value: '0', label: i18next.t('survey_select_usersports_0') }
        ];
    }

    handleOptionChange = (option) => {
        this.props.handleChange('sports', option);
    }

    handleCloseError = () => {
        this.setState({ missingChoiceError: false });
    }

    continue = e => {
        e.preventDefault();

        if (!validateSurveyFields('select', this.props.values.sports)) {
            this.setState({ missingChoiceError: true });
            return;
        }

        this.props.nextStep();
    };

    render() {
        const { values, t } = this.props;

        return (
            <SurveyPage
                title={t('survey_question_usersports')}
                content={[
                    <TableContainer component={Paper}>
                        <Table aria-label='custom pagination table'>
                            <TableBody>
                                {this.options.map(({ value, label }, index) => {
                                    return (
                                        <TableRow key={`sport-freq-row-${index}}`} style={{ width: "100%" }}>
                                            <label style={{ cursor: "pointer" }}>
                                                <TableCell>
                                                    <input type='checkbox'
                                                        id={`sport-freq-checkbox-${index}`}
                                                        name={value}
                                                        checked={values.sports === value}
                                                        onChange={() => this.handleOptionChange(value)}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ width: "100%" }}>{label}</TableCell>
                                            </label>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>,
                    <ErrorAlert
                        open={this.state.missingChoiceError}
                        onClose={this.handleCloseError}
                        text={t('alert_missing_choice')}
                    />,
                    <SurveyButtonGroup back={() => { this.props.prevStep(); }} next={this.continue} />
                ]}
            />
        )
    }
}



export default withTranslation()(SportsQuestion);
