import React, { useState, useContext, createContext } from 'react';
import { configData } from '../../config.js';

const authContext = createContext();

// delivers auth context for entire app after signing in
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem('user')));

    const signin = (email, password) => {
        let url = `${configData.API_URL}/user/login`;
        let credentials = { email: email, password: password };
        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        };
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((user) => {
                if (user === 'Control users cannot login') {
                    return 'Control users cannot login';
                }
                if (user) {
                    // store user details and basic auth credentials in local storage
                    // to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                    setUser(user);
                }
                return user;
            });
    };

    const logout = () => {
        localStorage.removeItem('user');
        setUser(null);
    };

    return {
        user,
        signin,
        logout,
    };
}

export const signUpService = {
    checkEmailExists,
    sendRegistrationMin,
    sendCompleteRegistration,
    sendSignUpSurvey,
    getUserBAMLink,
};

// checks if email is already connected to an existing account
function checkEmailExists(email, noAlert = false) {
    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
    };
    return fetch(`${configData.API_URL}/user/check-if-email-used/${email}`, requestOptions)
        .then(handleResponse)
        .catch(() => {
            if (noAlert === false) {
                alert('connection to the database failed');
            }
        });
}

function sendRegistrationMin(credentials, controlGroup) {
    let userType;
    if (controlGroup) {
        userType = 'Control';
    } else {
        userType = 'Experiment';
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...credentials, userType: userType }),
    };
    return fetch(`${configData.API_URL}/user/register-minimal`, requestOptions)
        .then(handleResponse)
        .then((token) => {
            if (token) {
                localStorage.setItem('user', JSON.stringify({ token: token }));
            }
            return token;
        })
        .catch(() => {
            return null;
        });
}

function sendCompleteRegistration(data, jwtToken) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'PUT',
        headers: {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
            ...(user && { Authentication: user.token }),
            jwt: jwtToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(`${configData.API_URL}/user/complete-registration`, requestOptions)
        .then((res) => {
            if (res.ok) {
                return true;
            } else return false;
        })
        .catch(() => {
            return null;
        });
}

// sends sign up data for all users to backend
// returns received user data or error if invalid credentials
function sendSignUpSurvey(signUpData) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(signUpData),
    };
    return fetch(`${configData.API_URL}/user/register`, requestOptions)
        .then(handleResponse)
        .then((user) => {
            return user;
        })
        .catch(() => {
            return null;
        });
}

// gets the unique user bam connection link
function getUserBAMLink() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token && user.link) {
        return user.link;
    } else {
        return null;
    }
}

// handles backend response, success or fail
function handleResponse(response) {
    return response.text().then((text) => {
        if (response.ok) {
            const data = text && JSON.parse(text);
            return data;
        } else {
            if (response.status === 400 && text === 'Control users cannot login') {
                return 'Control users cannot login';
            }
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
            }
            return null;
        }
    });
}

export const sendResetPasswordMail = async (email) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email }),
        };
        return await fetch(`${configData.API_URL}/user/pwreset`, requestOptions).then((r) =>
            r.text().then((data) => ({ status: r.status, body: data }))
        );
    } catch (error) {
        console.log('Check email error: ', error);
        return null;
    }
};

export const sendChangePasswordRequest = async (token, password) => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: {
                Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: token, password: password }),
        };
        return (
            (await fetch(`${configData.API_URL}/user/update-password`, requestOptions).then((r) => r.status)) === 200
        );
    } catch (error) {
        console.log('Check email error: ', error);
        return null;
    }
};

const stopCollectingData = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
            ...(user && { Authentication: user.token }),
        },
    };
    return fetch(`${configData.API_URL}/user/stop-collecting`, requestOptions)
        .then((res) => {
            return res.status;
        })
        .catch(() => {
            return null;
        });
};

const deleteAccount = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'delete',
        headers: {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
            ...(user && { Authentication: user.token }),
        },
    };
    return fetch(`${configData.API_URL}/user/delete`, requestOptions)
        .then((res) => {
            return res.status;
        })
        .catch(() => {
            return null;
        });
};

export const removeUser = {
    stopCollectingData,
    deleteAccount,
};
