import React, { Component } from 'react';
import { AppBar, Toolbar, Typography, ThemeProvider, CssBaseline } from '@mui/material';
import LanguageMenu from './LanguageMenu';
import { darkColourTheme } from '../../styles/ColourTheme';


export class LanguageHeader extends Component {
    render() {
        return (
            <ThemeProvider theme={darkColourTheme}>
                <CssBaseline />
                <AppBar position="absolute" color='primary'>
                    <Toolbar>
                        <Typography sx={{ flexGrow: 1 }}>
                            {/* This empty typography will push the language select dropdown to the right */}
                        </Typography>
                        <LanguageMenu />
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        )
    }
}

export default LanguageHeader;
