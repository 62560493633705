import React from "react";
import { Link } from 'react-router-dom';
import { Button, Container, Typography, ThemeProvider, Box } from "@mui/material";
import colourTheme from "../styles/ColourTheme";
import { useTranslation } from "react-i18next";
import { centeredBox, mainButtonStyle } from "../styles/ComponentStylings";


const Error404Page = () => {
    const { t } = useTranslation();

    return (
        <div className="Error404Page">
            <ThemeProvider theme={colourTheme}>
                <Container component="main" maxWidth="s">
                    <Box sx={centeredBox} >
                        <Typography component="h1" variant="h4" align="center">
                            Oups! Page not found.
                        </Typography>
                        <Box sx={{ marginTop: 8, display: 'inline-block', '& button': { m: 1 } }}>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    sx={mainButtonStyle}
                                >
                                    {t('button_home')}
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </div>
    );
}

export default Error404Page;