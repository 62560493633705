import { configData } from '../../config.js';

export const scraperService = {
    scraperRequest,
    updateScraperCredentials,
};

function scraperRequest(method, endpoint, migrosEmail, migrosPassword, coopEmail, coopPassword) {
    let user = JSON.parse(localStorage.getItem('user'));

    const requestOptions = {
        method,
        headers: {
            endpoint,
            ...(user && { Authentication: user.token }),
            'migros-email': migrosEmail,
            'migros-password': migrosPassword,
            'coop-email': coopEmail,
            'coop-password': coopPassword,
        },
    };
    return fetch(`${configData.API_URL}/scraper-api`, requestOptions).then((res) => {
        if (res.status === 200) {
            return true;
        } else return false;
    });
}

function updateScraperCredentials(migrosEmail, migrosPassword, coopEmail, coopPassword) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            ...(user && { Authentication: user.token }),
            endpoint: '/user/update-credentials',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            migrosEmail,
            migrosPassword,
            coopEmail,
            coopPassword,
        }),
    };
    return fetch(`${configData.API_URL}/scraper-api`, requestOptions).then((res) => {
        return res.status;
    });
}
