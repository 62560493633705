import * as React from 'react';
import { Typography, Divider, Box, Container, Grid, Paper, Card, CardContent } from '@mui/material';
import { BackTooltip } from '../components/Tooltip/Tooltips';
import CircularLoading from '../components/Loading/CircularLoading';
import { useTranslation } from 'react-i18next';
import ShoppingHistoryTable from '../components/Tables/ShoppingHistoryTable';
import { userService } from '../services/User/userService';
import useSWR from 'swr';

export default function ShoppingHistoryPage(props) {
    const { t } = useTranslation();
    const handleBack = () => {
        props.handleBack();
    };

    const { data, error } = useSWR('/user/shopping-history', (url) => userService.getShoppingHistoryData(url));

    return (
        <>
            <Grid container>
                <Grid item xs={2} sx={{ alignItems: 'center' }}>
                    <BackTooltip onClick={handleBack} sx={{ pt: 2 }} />
                </Grid>
                <Typography variant="h6" gutterBottom sx={{ pt: 1.5, textAlign: 'center' }}>
                    {props.pageTitle}
                </Typography>
            </Grid>
            <Divider />
            {error ? (
                <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                    <CardContent>
                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                            {t('error_fetching_profile_data')}
                        </Typography>
                    </CardContent>
                </Card>
            ) : !data ? (
                <CircularLoading />
            ) : (
                <Container fullwidth="true" sx={{ mt: 2, mb: 9 }}>
                    <Grid>
                        <Paper
                            sx={{
                                display: 'grid',
                                height: 'auto',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                {data !== 'No data' ? (
                                    <ShoppingHistoryTable data={data} />
                                ) : (
                                    <Card sx={{ bgcolor: '#17a2b8', m: 2 }}>
                                        <CardContent>
                                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                                {t('infotext_noshoppinghistory')}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                </Container>
            )}
        </>
    );
}
