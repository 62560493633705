const sports = [
    { value: '4+', label: 'survey_select_usersports_4' },
    { value: '2-3', label: 'survey_select_usersports_3' },
    { value: '1', label: 'survey_select_usersports_2' },
    { value: '<1', label: 'survey_select_usersports_1' },
    { value: '0', label: 'survey_select_usersports_0' }
];

const educations = [
    { value: 'noFormalEducation', label: 'survey_select_usereducation_noFormalEducation' },
    { value: 'obligatory', label: 'survey_select_usereducation_obligatory' },
    { value: 'apprenticeship', label: 'survey_select_usereducation_apprenticeship' },
    { value: 'high', label: 'survey_select_usereducation_high' },
    { value: 'university', label: 'survey_select_usereducation_university' },
];


const activityLevelAtWork = [
    { value: 'sittingLying', label: 'survey_select_useractivity_lying' },
    { value: 'exclusivelySedentary', label: 'survey_select_useractivity_exclusivelySedentary' },
    { value: 'sedentary', label: 'survey_select_useractivity_sedentary' },
    { value: 'goingStanding', label: 'survey_select_useractivity_standing' },
    { value: 'strenuous', label: 'survey_select_useractivity_strenuous' }
];


const disorders = [
    { value: 'Obesity', label: 'diseases_obesity' },
    { value: 'Diabetes', label: 'diseases_diabetes' },
    { value: 'Dyslipidemia', label: 'diseases_dyslipidemia' },
    { value: 'Arterial hypertension', label: 'diseases_arterialhypertension' },
    { value: 'Atherosclerotic cardiovascular disease', label: 'diseases_atheroscleroticcardiovasculardisease' },
    { value: 'Other', label: '' },
    { value: 'None of the above', label: 'diseases_none' }
];


export const allergies = [
    { value: 'Eggs', label: 'allergens_eggs' },
    { value: 'SesameSeeds', label: 'allergens_sesame' },
    { value: 'Gluten', label: 'allergens_gluten' },
    { value: 'Soy', label: 'allergens_soy' },
    { value: 'Nuts', label: 'allergens_nuts' },
    { value: 'Peanuts', label: 'allergens_peanuts' },
    { value: 'Sulphites', label: 'allergens_sulphites' },
    { value: 'Mustards', label: 'allergens_mustards' },
    { value: 'Cellery', label: 'allergens_cellery' },
    { value: 'Fish', label: 'allergens_fish' },
    { value: 'Lupin', label: 'allergens_lupin' },
    { value: 'Crustaceans', label: 'allergens_crustaceans' },
    { value: 'Molluscs', label: 'allergens_molluscs' },
    { value: 'Milk', label: 'allergens_milk' }
];

export function getSportsLabel(sportId) {
    try {
        let sport = sports.find(({ value }) => value === sportId);
        return sport.label;
    } catch (error) {
        return '';
    }
}

export function getActivityLevelAtWork(activityLevel) {
    try {
        let activity = activityLevelAtWork.find(({ value }) => value === activityLevel);
        return activity.label;
    } catch (error) {
        return '';
    }
}

export function getEducationLabel(education) {
    try {
        let edu = educations.find(({ value }) => value === education);
        return edu.label;
    }
    catch (error) {
        return '';
    }
}

export function getDisorderLabel(disorder) {
    try {
        let dis = disorders.find(({ value }) => value === disorder);
        return dis.label;
    } catch (error) {
        return '';
    }

}

export function getAllergyLabel(allergyValue) {
    try {
        let allergy = allergies.find(({ value }) => value === allergyValue);
        return allergy.label;
    } catch (error) {
        return '';
    }
}
