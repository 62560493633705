import React from 'react';
import {
    Typography,
    Paper,
    Button,
    Grid,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import DashboardPage from './DashboardPage';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth, removeUser } from '../services/Authentication/useAuth';
import { useNavigate } from 'react-router-dom';
import LanguageMenu from '../components/Language/LanguageMenu';
import { WarningAlert } from '../components/Alert/Alerts';
import ReconnectLoyaltyCards from '../pages/ReconnectLoyaltyCards';
import FetchLoyaltyCards from '../pages/FetchLoyaltyCards';

const SettingsPage = () => {
    const { t } = useTranslation();
    let auth = useAuth();
    let navigate = useNavigate();

    const handleLogout = () => {
        auth.logout();
        navigate('/');
    };
    const classes = useStyles();

    const [openStopCollectingDataDialog, setOpenStopCollectingDataDialog] = React.useState(false);
    const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = React.useState(false);

    const stopCollectingData = async () => {
        setOpenStopCollectingDataDialog(false);
        const status = await removeUser.stopCollectingData();
        if (status === 200) {
            handleLogout();
        } else {
            alert(t('something_went_wrong'));
        }
    };

    const deleteAccount = async () => {
        setOpenDeleteAccountDialog(false);
        const status = await removeUser.deleteAccount();
        if (status === 200) {
            handleLogout();
        } else {
            alert(t('something_went_wrong'));
        }
    };

    return (
        <DashboardPage
            content={
                <Paper
                    sx={{
                        mt: 2,
                        mb: 9,
                        display: 'grid',
                        height: 'auto',
                    }}
                >
                    <Typography variant="h5" noWrap align="center" sx={{ mt: 2, mb: 2 }}>
                        {t('settings')}
                    </Typography>
                    <Grid container justifyContent="center" sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                            <Typography variant="body1" noWrap align="center" sx={{ mt: 2, mb: 2 }}>
                                {t('change_language')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <LanguageMenu color="primary" />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container justifyContent="center" sx={{ mt: 2, mb: 2 }}>
                        <Button variant="outlined" color="alert" startIcon={<LogoutIcon />} onClick={handleLogout}>
                            logout
                        </Button>
                    </Grid>
                    <Divider />
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.content }}>
                            <Typography variant="h8">{t('loyalty_card_connection')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FetchLoyaltyCards />
                            <ReconnectLoyaltyCards />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.content }}>
                            <Typography variant="h8">{t('leave_study_options')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            // allign items to center
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Button
                                    variant="outlined"
                                    color="alert"
                                    startIcon={<LogoutIcon />}
                                    onClick={() => setOpenStopCollectingDataDialog(true)}
                                    sx={{ mb: 5 }}
                                >
                                    {t('leave_study')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="alert"
                                    startIcon={<LogoutIcon />}
                                    onClick={() => setOpenDeleteAccountDialog(true)}
                                >
                                    {t('delete_account')}
                                </Button>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <WarningAlert
                        open={openStopCollectingDataDialog}
                        onClose={() => setOpenStopCollectingDataDialog(false)}
                        text={t('alert_message_stopcollectingdata')}
                        content={
                            <Container
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: '10px',
                                }}
                            >
                                <Button color="secondary" variant="contained" onClick={stopCollectingData}>
                                    {t('yes')}
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    sx={{ ml: '10px' }}
                                    onClick={() => setOpenStopCollectingDataDialog(false)}
                                >
                                    {t('no')}
                                </Button>
                            </Container>
                        }
                    />
                    <WarningAlert
                        open={openDeleteAccountDialog}
                        onClose={() => setOpenDeleteAccountDialog(false)}
                        text={t('alert_message_deleteaccount')}
                        content={
                            <Container
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: '10px',
                                }}
                            >
                                <Button color="secondary" variant="contained" onClick={deleteAccount}>
                                    {t('yes')}
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    sx={{ ml: '10px' }}
                                    onClick={() => setOpenDeleteAccountDialog(false)}
                                >
                                    {t('no')}
                                </Button>
                            </Container>
                        }
                    />
                </Paper>
            }
        />
    );
};

const useStyles = makeStyles({
    content: {
        justifyContent: 'center',
    },
});

export default SettingsPage;
