import React, { useState } from 'react';
import { Avatar, Button, CssBaseline, TextField, Grid, Box, Typography, Container, ThemeProvider } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from 'react-router-dom';
import { colourTheme } from '../../styles/ColourTheme';
import LanguageHeader from '../Language/LanguageHeader';
import { useTranslation } from 'react-i18next';
import { linkWithUnderline } from '../../styles/ComponentStylings';
import validateAuthFields from '../../utils/formValidator';
import { ErrorAlert } from '../Alert/Alerts';
import { InfoAlert } from '../Alert/Alerts';
import { sendChangePasswordRequest } from '../../services/Authentication/useAuth';

const ChangePassword = () => {
    const { t } = useTranslation();
    const [passwordConfirmError, setPasswordConfirmError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [jwtOutdatedError, setJwtOutdatedError] = useState(false);
    const [passwordChangeError, setPasswordChangeError] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        password: '',
        passwordConfirm: '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        if (fields.password === fields.passwordConfirm) {
            if (validateAuthFields('password', fields.password)) {
                const token = new URLSearchParams(window.location.search).get('token');
                sendChangePasswordRequest(token, fields.password).then((success) => {
                    if (success == null) {
                        setPasswordChangeError(true);
                    } else if (success) {
                        setPasswordChanged(true);
                    } else {
                        setJwtOutdatedError(true);
                    }
                });
            } else {
                console.log('token expired');
                setPasswordChangeError(true);
            }
        } else {
            setPasswordConfirmError(true);
        }
    };
    // form_error_password
    // form_error_passwordmatch

    return (
        <ThemeProvider theme={colourTheme}>
            <LanguageHeader />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1 }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography component="h1" variant="h6" align="center">
                                    {t('changepassword_enterpassword')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={t('form_password')}
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={fields.password}
                                    onChange={handleFieldChange}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={t('form_password')}
                                    type="password"
                                    id="passwordConfirm"
                                    autoComplete="current-password"
                                    value={fields.passwordConfirm}
                                    onChange={handleFieldChange}
                                />
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            {t('changepassword')}
                        </Button>

                        <ErrorAlert
                            open={passwordConfirmError}
                            onClose={() => {
                                setPasswordConfirmError(false);
                            }}
                            text={t('form_error_passwordmatch')}
                        />
                        <ErrorAlert
                            open={passwordError}
                            onClose={() => {
                                setPasswordError(false);
                            }}
                            text={t('form_error_password')}
                        />
                        <ErrorAlert
                            open={jwtOutdatedError}
                            onClose={() => {
                                setJwtOutdatedError(false);
                            }}
                            text={t('alert_jwt_outdated')}
                        />
                        <ErrorAlert
                            open={passwordChangeError}
                            onClose={() => {
                                setPasswordChangeError(false);
                            }}
                            text={t('alert_passwordresetnotsent')}
                        />
                        <InfoAlert
                            open={passwordChanged}
                            onClose={() => {
                                setPasswordChanged(false);
                            }}
                            text={t('info_passwordreset')}
                        />

                        <Grid container>
                            <Grid item>
                                <Link to="/" style={linkWithUnderline}>
                                    {t('button_home')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

/**
 * custom hook for common stateful logic of all form related components
 * @param initialState takes the initial state of form field as an object and saves it as a state variable called fields
 */
function useFormFields(initialState) {
    const [fields, setValues] = useState(initialState);

    return [
        fields,
        function (event) {
            setValues({
                ...fields,
                [event.target.id]: event.target.value,
            });
        },
    ];
}

export default ChangePassword;
