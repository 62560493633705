import React, { Component } from 'react'
import { InputAdornment, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';
import SurveyPage from '../../pages/SurveyPage';
import { SurveyTextField, SurveyButtonGroup } from '../../utils/commonComponents';
import { validateSurveyFields } from '../../utils/formValidator';


class UserShoppingMigros extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validated: false,
            percShoppingMigrosErrorText: 'error',
            usageMigrosErrorText: 'error',
            value: props.value
        }
    }

    validInput() {
        let percMigrosValidity = false;
        let useMigrosValidity = false;

        if (!validateSurveyFields('percentage', this.props.values.percShoppingMigros)) {
            this.setState({ percShoppingMigrosErrorText: 'Invalid input!' });
            percMigrosValidity = false;
        } else {
            this.setState({ percShoppingMigrosErrorText: '' });
            percMigrosValidity = true;
        }

        if (!validateSurveyFields('percentage', this.props.values.usageMigros)) {
            this.setState({ usageMigrosErrorText: 'Invalid input!' });
            useMigrosValidity = false;
        } else {
            this.setState({ usageMigrosErrorText: '' });
            useMigrosValidity = true;
        }

        this.setState({ validated: true });
        return percMigrosValidity && useMigrosValidity;
    }

    validateForm = (e) => {
        e.preventDefault();
        if (!this.validInput()) return;
        this.props.nextStep();
    }

    render() {
        const { values, handleChange, handleNumberInput, t } = this.props;

        return (
            <SurveyPage
                title={t('survey_question_foodshoppingmigros')}
                content={[
                    <SurveyTextField
                        id='percShoppingMigros'
                        label={"Migros " + t('profile_shoppingfrequency') + " (in %)"}
                        value={values.percShoppingMigros}
                        // placeholder={t('form_percent')}
                        onChange={(e) => handleChange('percShoppingMigros', handleNumberInput(e.target.value))}
                        inputProps={{ inputMode: 'numeric', endAdornment: (<InputAdornment position='end'>%</InputAdornment>) }}
                        error={this.state.validated && this.state.percShoppingMigrosErrorText.length !== 0}
                        helperText={this.state.validated && this.state.percShoppingMigrosErrorText.length !== 0 ? t('form_error_number') : ''}
                        autoFocus={true}
                    />,
                    <Typography
                        variant='h6'
                        align='center'
                        sx={{ mt: 2 }}
                    >
                        {t('survey_question_remembercardmigros') + " (in %)"}
                    </Typography>,
                    <SurveyTextField
                        id='shopping-use-migros'
                        label={"Migros " + t('profile_loyaltycardusage')}
                        value={values.usageMigros}
                        // placeholder={t('form_percent')}
                        onChange={(e) => handleChange('usageMigros', handleNumberInput(e.target.value))}
                        inputProps={{ inputMode: 'numeric', endAdornment: (<InputAdornment position='end'>%</InputAdornment>) }}
                        error={this.state.validated && this.state.usageMigrosErrorText.length !== 0}
                        helperText={this.state.validated && this.state.usageMigrosErrorText.length !== 0 ? t('form_error_number') : ''}
                    />,
                    <SurveyButtonGroup back={() => { this.props.prevStep(); }} next={this.validateForm} />
                ]}
            />
        )
    }
}

export default withTranslation()(UserShoppingMigros);
