import React from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, Typography } from '@mui/material';
import DashboardPage from '../../pages/DashboardPage';
import { userService } from '../../services/User/userService';
import CircularLoading from '../Loading/CircularLoading';
import { useTranslation } from 'react-i18next';
import { ProfileInfo } from '../../utils/commonComponents';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useSWR from 'swr';
import {
    getSportsLabel,
    getEducationLabel,
    getActivityLevelAtWork,
    getDisorderLabel,
    getAllergyLabel,
} from '../../utils/ListOfThings';

export default function Profile() {
    const { t } = useTranslation();
    let sportLable = '';
    let educationLable = '';
    let activityLable = '';
    let birthdate = null;

    const { data, error } = useSWR('/user/survey', userService.getSurveyData);

    if (error) {
        console.log('An error occured while fetching the profile data');
        return (
            <DashboardPage
                content={
                    <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                        <CardContent>
                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                {t('error_fetching_profile_data')}
                            </Typography>
                        </CardContent>
                    </Card>
                }
            />
        );
    }

    const toUpperCase = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const getDiseasesValue = () => {
        let diseases = [];

        if (data.disorders) {
            data.disorders.forEach((dis) => {
                let translation = t(getDisorderLabel(dis));
                translation.length !== 0 && diseases.push(translation);
            });
        }
        if (data.otherDiseases) diseases.push(data.otherDiseases);
        return diseases.join(', ');
    };

    const getAllergiesValue = () => {
        let allergies = [];
        if (data.allergiesAndAbstentions.allergies) {
            data.allergiesAndAbstentions.allergies.forEach((allergy) => {
                let translation = t(getAllergyLabel(allergy));
                allergies.push(translation);
            });
        }
        return allergies.join(', ');
    };

    const getAbstentionsValue = () => {
        let abstentions = [];
        if (data.allergiesAndAbstentions.abstentions) {
            data.allergiesAndAbstentions.abstentions.forEach((abst) => {
                let translation = t(getAllergyLabel(abst));
                abstentions.push(translation);
            });
        }
        return abstentions.join(', ');
    };

    // TODO: Test possible breakpoints!!
    // all getters here need to be tested
    // do they cover the correct returns from the backend?
    if (data) {
        sportLable = getSportsLabel(data.sports);
        educationLable = getEducationLabel(data.education);
        activityLable = getActivityLevelAtWork(data.activityLevelAtWork);
        birthdate = new Date(data.birthdate);
    }
    return (
        <DashboardPage
            content={
                <>
                    {!data ? (
                        <CircularLoading />
                    ) : (
                        <Box sx={{ pt: 2, pb: 8 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {t('profile_personalinfo')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ProfileInfo
                                        label={t('form_label_birthdate')}
                                        value={birthdate.toLocaleDateString('de-DE')}
                                    />
                                    <ProfileInfo label={t('gender')} value={t(data.gender.toLowerCase())} />
                                    <ProfileInfo label={t('height')} value={`${data.height} cm`} />
                                    <ProfileInfo label={t('weight')} value={`${data.weight} kg`} />
                                    <ProfileInfo label="Education" value={t(educationLable)} />
                                    {data.householdIncome === 'prefer not to answer' ? (
                                        <ProfileInfo label={t('householdIncome')} value={t('not_answered')} />
                                    ) : (
                                        <ProfileInfo label={t('householdIncome')} value={data.householdIncome} />
                                    )}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {t('profile_allergiesandabstentions')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {data.allergiesAndAbstentions.allergies.length !== 0 && (
                                        <ProfileInfo label={t('allergens')} value={getAllergiesValue()} />
                                    )}
                                    {(data.allergiesAndAbstentions.abstentions.length !== 0 || data.meat) && (
                                        <ProfileInfo
                                            label={t('abstentions')}
                                            value={
                                                data.meat
                                                    ? getAbstentionsValue()
                                                    : `${getAbstentionsValue()}, ${t('meat')}`
                                            }
                                        />
                                    )}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {t('profile_health')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* TODO: check if other diseases correct display */}
                                    {!data.disorders.includes('None of the above') && (
                                        <ProfileInfo label={t('disorders')} value={getDiseasesValue()} />
                                    )}
                                    <ProfileInfo label={t('profile_activitylevel')} value={t(activityLable)} />
                                    <ProfileInfo label="Sports" value={t(sportLable)} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {t('profile_loyaltycards')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ProfileInfo
                                        label={t('loyaltycards')}
                                        value={
                                            data.loyaltyCards === 'both'
                                                ? 'Migros, Coop'
                                                : toUpperCase(data.loyaltyCards)
                                        }
                                    />
                                    {data.percShoppingMigros !== -1 && (
                                        <ProfileInfo
                                            label={`Migros ${t('profile_shoppingfrequency')}`}
                                            value={`${data.percShoppingMigros} %`}
                                        />
                                    )}
                                    {data.usageMigros !== -1 && (
                                        <ProfileInfo
                                            label={`Migros ${t('profile_loyaltycardusage')}`}
                                            value={`${data.usageMigros} %`}
                                        />
                                    )}
                                    {data.percShoppingCoop !== -1 && (
                                        <ProfileInfo
                                            label={`Coop ${t('profile_shoppingfrequency')}`}
                                            value={`${data.percShoppingCoop} % `}
                                        />
                                    )}
                                    {data.usageCoop !== -1 && (
                                        <ProfileInfo
                                            label={`Coop ${t('profile_loyaltycardusage')}`}
                                            value={`${data.usageCoop} %`}
                                        />
                                    )}
                                    <ProfileInfo label={t('profile_adults')} value={data.loyaltyShareAdults} />
                                    <ProfileInfo label={t('profile_teens')} value={data.loyaltyShareTeens} />
                                    <ProfileInfo label={t('profile_kids')} value={data.loyaltyShareKids} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {t('profile_shopping')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ProfileInfo label={t('form_label_fruits')} value={data.percFruits + ' %'} />
                                    <ProfileInfo
                                        label={t('form_label_vegetables')}
                                        value={data.percVegetables + ' %'}
                                    />
                                    <ProfileInfo
                                        label={t('form_label_proteinfood')}
                                        value={data.percProteinFoods + ' %'}
                                    />
                                    <ProfileInfo
                                        label={t('form_label_processedfood')}
                                        value={data.percProcessedFoods + ' %'}
                                    />
                                    <ProfileInfo label={t('form_label_carbs')} value={data.percCarbs + ' %'} />
                                    <ProfileInfo label={t('form_label_oils')} value={data.percOils + ' %'} />
                                    <ProfileInfo label={t('form_label_beverages')} value={data.percBeverages + ' %'} />
                                </AccordionDetails>
                            </Accordion>
                            {/* Only display following accordion if there is data */}
                            {(data.historyUsingApps || data.currentlyUsingOtherApps) && (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        {t('profile_foodtrackingapps')}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {data.historyUsingApps && (
                                            <ProfileInfo
                                                label={t('profile_foodtrackingappsbefore')}
                                                value={data.historyApps}
                                            />
                                        )}
                                        {data.currentlyUsingOtherApps && (
                                            <ProfileInfo
                                                label={t('profile_foodtrackingappsnow')}
                                                value={data.currentOtherApps}
                                            />
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </Box>
                    )}
                </>
            }
        />
    );
}
