import React, { Component } from 'react';
import { ErrorAlert } from '../Alert/Alerts';
import SurveyPage from '../../pages/SurveyPage';
import { SurveyButtonGroup } from '../../utils/commonComponents';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Grid, Button, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';

class LoyaltyCardsQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            displayEligibilityAlert: false,
            navigateToHome: false,
        };
        this.options = [
            { value: 'migros', label: 'Migros Cumulus' },
            { value: 'coop', label: 'Coop Supercard' },
            { value: 'both', label: i18next.t('both') },
            { value: 'none', label: i18next.t('none') },
        ];
        this.handleCloseIneligibility = this.handleCloseIneligibility.bind(this);
    }

    handleOptionChange = (option) => {
        this.props.handleChange('loyaltyCards', option);
    };

    validateForm = (e) => {
        e.preventDefault();

        let option = this.props.values.loyaltyCards;

        // check if no selection made then return to wait for selection
        if (option === '') return;

        // check if 'none' was chosen which means ineligiblity for study participation
        if (option === 'none') {
            this.setState({ displayEligibilityAlert: true });
            return;
        }

        // proceed if eligible selection made
        this.props.nextStep();
    };

    handleCloseIneligibility() {
        this.setState({ displayEligibilityAlert: false });
        this.setState({ navigateToHome: true });
    }

    render() {
        const { values, t } = this.props;
        const { navigateToHome } = this.state;

        return navigateToHome ? (
            <Navigate to="/" />
        ) : (
            <SurveyPage
                title={
                    this.props.datafetchingMethod === 'HSG' ? t('survey_step_2_hsg') : t('survey_step_1_bitsaboutme')
                }
                content={[
                        <Typography variant="h6" align="center">
                            {t('survey_question_userloyaltycards')}
                        </Typography>,
                        <TableContainer component={Paper}>
                        <Table aria-label='custom pagination table'>
                            <TableBody>
                                {this.options.map(({ value, label }, index) => {
                                    return (
                                        <TableRow key={`loyalty-card-row-${index}}`} style={{ width: "100%" }}>
                                            <label style={{ cursor: "pointer" }}>
                                                <TableCell>
                                                    <input type='checkbox'
                                                        id={`loyalty-card-checkbox-${index}`}
                                                        name={value}
                                                        checked={values.loyaltyCards === value}
                                                        onChange={() => this.handleOptionChange(value)}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ width: "100%" }}>{label}</TableCell>
                                            </label>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>,
                    <ErrorAlert
                        open={this.state.missingChoiceError}
                        onClose={this.handleCloseError}
                        text={t('alert_missing_choice')}
                    />,
                    <ErrorAlert
                        open={this.state.displayEligibilityAlert}
                        onClose={this.handleCloseIneligibility}
                        text={t('alert_ineligibility')}
                    />,
                    this.props.datafetchingMethod === 'HSG' ? (
                        <Grid container justifyContent="space-around" sx={{ pt: 2, pb: 2 }}>
                            <Button
                                variant="contained"
                                color="inherit"
                                size="large"
                                aria-label="large button group"
                                onClick={this.props.nextStep}
                            >
                                {t('button_next')}
                            </Button>
                        </Grid>
                    ) : (
                        <SurveyButtonGroup back={this.props.prevStep} next={this.props.nextStep} />
                    ),
                ]}
            />
        );
    }
}

export default withTranslation()(LoyaltyCardsQuestion);
