import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TableHead, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import category_matching from '../../assets/language/major_categories.json';
import { t } from 'i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { InfoAlert } from '../Alert/Alerts';

export default function AnalysisTable(props) {
    let rows = props.data;
    const { i18n } = useTranslation();
    const [showAlert, setShowAlert] = React.useState(false);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">{t('category')}</TableCell>
                        {/* <TableCell /> */}
                        <TableCell align="center">{props.type === 'Spending' ? t('price') : 'Kcal'}</TableCell>
                        <TableCell align="center">%</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) =>
                        row.name === 'Not categorized' ? (
                            <TableRow key={`Spending-analysis-table-row-${Math.random()}`}>
                                <TableCell sx={{ width: '80%' }} align="left" onClick={() => setShowAlert(!showAlert)}>
                                    <Box sx={{ display: 'flex' }}>
                                        {i18n.language === 'de' ? category_matching[row.name] : row.name}
                                        <InfoOutlinedIcon style={{ marginLeft: 2 }} />
                                    </Box>
                                </TableCell>
                                {/* <TableCell sx={{ width: "5%" }} onClick={() => setShowAlert(!showAlert)}>
                                    <InfoOutlinedIcon />
                                </TableCell> */}
                                <TableCell sx={{ width: '10%' }} align="center">
                                    {isNaN(row.secondCol) ? row.secondCol : Math.round(row.secondCol)}
                                </TableCell>
                                <TableCell sx={{ width: '10%' }} align="center">
                                    {isNaN(row.percent) ? row.percent : Math.round(row.percent)}
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow key={`Spending-analysis-table-row-${Math.random()}`}>
                                <TableCell sx={{ width: '80%' }} align="left">
                                    {i18n.language === 'de' ? category_matching[row.name] : row.name}
                                </TableCell>
                                {/* <TableCell sx={{ width: "5%" }} align="center" /> */}
                                <TableCell sx={{ width: '10%' }} align="center">
                                    {isNaN(row.secondCol) ? row.secondCol : Math.round(row.secondCol)}
                                </TableCell>
                                <TableCell sx={{ width: '10%' }} align="center">
                                    {isNaN(row.percent) ? row.percent : Math.round(row.percent)}
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
            <InfoAlert open={showAlert} onClose={() => setShowAlert(false)} text={t('info_not_categorized')} />
        </TableContainer>
    );
}
