import React, { Component } from 'react';
import { AppBar, Toolbar, Grid } from '@mui/material';
import LanguageMenu from './LanguageMenu';
import { withTranslation } from 'react-i18next';

export class SurveyLanguageHeader extends Component {
    render() {
        return (
            <>
                <AppBar position="absolute">
                    <Toolbar>
                        <Grid container spacing={1} justifyContent="right">
                            <LanguageMenu />
                        </Grid>
                    </Toolbar>
                </AppBar>
            </>
        );
    }
}

export default withTranslation()(SurveyLanguageHeader);
