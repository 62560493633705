import React, { useState } from 'react';
import {
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Collapse,
    IconButton,
    Grid,
    TableHead,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { nutrientScores } from '../../utils/NutrientScore';
import i18next from 'i18next';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';

function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const { t } = i18next;

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {new Date(row.basket_timestamp * 1000).toLocaleDateString('de-DE')}
                </TableCell>
                <TableCell align="center">{nutrientScores.get(row.basket_nutri_score)}</TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: 'white' }}>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small" aria-label="shopping-history-ItemList">
                            <TableBody>
                                {row.item_list.map((basketRow) => {
                                    const image_url = basketRow.product_image?.replace(
                                        'https://eatfitmedias.blob.core.windows.net',
                                        'https://eatfit-service.dietcoach.ch'
                                    );
                                    return (
                                        <TableRow
                                            key={row.basket_timestamp + Math.random().toString(4).slice(2)}
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            <TableCell style={{ padding: 3 }} align="center">
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    direction="column"
                                                >
                                                    <Grid item fontSize={12}>
                                                        {image_url && (
                                                            <img src={image_url} width="40" height="40" alt="Product" />
                                                        )}
                                                    </Grid>
                                                    <Grid item>{basketRow.item_name}</Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell style={{ padding: 3 }} align="center">
                                                {basketRow.item_nutri_score &&
                                                    nutrientScores.get(basketRow.item_nutri_score)}
                                            </TableCell>
                                            <TableCell style={{ padding: 3 }} align="center">
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    direction="column"
                                                >
                                                    <Grid item fontSize={12}>
                                                        {basketRow.item_quantity % 1 !== 0
                                                            ? Math.round(basketRow.item_quantity * 1000) + ' g'
                                                            : Math.round(basketRow.item_quantity * 1000) / 1000 +
                                                              ' ' +
                                                              t('unit')}
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell style={{ padding: 5, fontSize: 12 }} align="center">
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    direction="column"
                                                >
                                                    <Grid item fontSize={12}>
                                                        {basketRow.item_price}
                                                    </Grid>
                                                    <Grid item>CHF</Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default function ShoppingHistoryTable(props) {
    const [locale] = useState(i18next.language);
    const data = props.data;

    const localeMap = {
        en: enLocale,
        de: deLocale,
    };

    return (
        <TableContainer component={Paper}>
            {data
                .filter((e) => e.baskets !== null)
                .sort((a, b) => b.month_timestamp - a.month_timestamp)
                .map((row) => {
                    const monthName = new Date(row.month_timestamp * 1000).toLocaleString(localeMap[locale], {
                        month: 'long',
                    });
                    let year = new Date(row.month_timestamp * 1000).getFullYear();
                    return (
                        <Table aria-label="collapsible table" key={row.month_timestamp}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{monthName + ' ' + year}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.baskets.map((basket) => (
                                    <Row
                                        key={basket.basket_timestamp + Math.random().toString(4).slice(2)}
                                        row={basket}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    );
                })}
        </TableContainer>
    );
}
