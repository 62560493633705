import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, ThemeProvider, Box, Button } from '@mui/material';
import colourTheme from '../styles/ColourTheme';
import { withTranslation } from 'react-i18next';
import { flexBox, mainButtonStyle } from '../styles/ComponentStylings';
import SurveyLanguageHeader from '../components/Language/SurveyLanguageHeader';
// import { useReactPWAInstall } from "react-pwa-install";
// import myLogo from "../assets/favicon.ico";

const SurveySuccessPage = (props) => {
    const { t, controlGroup } = props;
    // const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

    // const handleClick = () => {
    //     pwaInstall({
    //         title: t("install_foodcoach"),
    //         logo: myLogo,
    //         features: (
    //             <ul>
    //                 <li>{t("install_feature_1")}</li>
    //                 <li>{t("install_feature_2")}</li>
    //                 <li>{t("install_feature_3")}</li>
    //             </ul>
    //         ),
    //     })
    // };

    return (
        <div className="SignUpCompletePage">
            <ThemeProvider theme={colourTheme}>
                <SurveyLanguageHeader />
                <Container component="main" maxWidth="s">
                    <Box
                        sx={{
                            pt: 12,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: 5,
                        }}
                    >
                        <Typography component="h1" align="center" marginTop={5}>
                            {controlGroup ? (
                                t('text_signupsuccess_controlgroup')
                            ) : (
                                // text with line break
                                <div>
                                    {t('text_signupsuccess_usergroup_1')}
                                    <br />
                                    <br />
                                    {t('text_signupsuccess_usergroup_2')}
                                </div>
                            )}
                        </Typography>
                        {!controlGroup && (
                            <Box sx={flexBox}>
                                {/* {supported() && !isInstalled() && (

                                    <Button
                                        variant='contained'
                                        sx={{
                                            bgcolor: 'green',
                                            color: 'white',
                                            align: "right",
                                            ml: "auto",
                                            textAlign: "center",
                                            width: "250px",
                                            margin: 1,
                                            padding: 2,
                                            marginBottom: 2,
                                            fontSize: "20px",
                                        }}
                                        onClick={handleClick}
                                    >
                                        {t("install_app")}
                                    </Button>
                                )} */}
                                <Link to="/signin" style={{ textDecoration: 'none' }}>
                                    <Button color="primary" variant="contained" sx={mainButtonStyle}>
                                        {t('button_home')}
                                    </Button>
                                </Link>
                            </Box>
                        )}
                    </Box>
                </Container>
            </ThemeProvider>
        </div>
    );
};

export default withTranslation()(SurveySuccessPage);
