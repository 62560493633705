import React, { Component } from 'react'
import { Typography, InputAdornment } from '@mui/material';
import { withTranslation } from 'react-i18next';
import SurveyPage from '../../pages/SurveyPage';
import { SurveyTextField, SurveyButtonGroup } from '../../utils/commonComponents';
import { validateSurveyFields } from '../../utils/formValidator';


class UserShopping extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validated: false,
            percShoppingMigrosErrorText: 'error',
            percShoppingCoopErrorText: 'error',
            percShoppingTotalErrorText: 'error',
            usageMigrosErrorText: 'error',
            usageCoopErrorText: 'error',
            value: props.value
        }
    }

    validInput() {
        let percMigrosValidity = false;
        let percCoopValidity = false;
        let percTotalValidity = false;
        let useMigrosValidity = false;
        let useCoopValidity = false;

        let percMigros = this.props.values.percShoppingMigros;
        let percCoop = this.props.values.percShoppingCoop;

        if (!validateSurveyFields('percentage', percMigros)) {
            this.setState({ percShoppingMigrosErrorText: 'Invalid input!' });
            percMigrosValidity = false;
        } else {
            this.setState({ percShoppingMigrosErrorText: '' });
            percMigrosValidity = true;
        }

        if (!validateSurveyFields('percentage', percCoop)) {
            this.setState({ percShoppingCoopErrorText: 'Invalid input!' });
            percCoopValidity = false;
        } else {
            this.setState({ percShoppingCoopErrorText: '' });
            percCoopValidity = true;
        }

        // since React has issues with the 'Number' type
        // we have to convert the values to numbers ourselves 
        // check that the total shopping percentage is not more than 100%
        if (Number(percMigros) + Number(percCoop) > 100) {
            this.setState({ percShoppingTotalErrorText: 'Invalid input!' });
            percTotalValidity = false;
        } else {
            this.setState({ percShoppingTotalErrorText: '' });
            percTotalValidity = true;
        }

        if (!validateSurveyFields('percentage', this.props.values.usageMigros)) {
            this.setState({ usageMigrosErrorText: 'Invalid input!' });
            useMigrosValidity = false;
        } else {
            this.setState({ usageMigrosErrorText: '' });
            useMigrosValidity = true;
        }
        if (!validateSurveyFields('percentage', this.props.values.usageCoop)) {
            this.setState({ usageCoopErrorText: 'Invalid input!' });
            useCoopValidity = false;
        } else {
            this.setState({ usageCoopErrorText: '' });
            useCoopValidity = true;
        }

        this.setState({ validated: true });
        return percMigrosValidity && percCoopValidity && percTotalValidity && useMigrosValidity && useCoopValidity;
    }

    validateForm = (e) => {
        e.preventDefault();
        if (!this.validInput()) return;
        this.props.nextStep();
    }

    render() {
        const { values, handleChange, handleNumberInput, t } = this.props;

        return (
            <SurveyPage
                title={t('survey_question_foodshopping')}
                content={[
                    <SurveyTextField
                        id='percShoppingMigros'
                        label={"Migros " + t('profile_shoppingfrequency')}
                        value={values.percShoppingMigros}
                        placeholder={t('form_percent')}
                        onChange={(e) => handleChange('percShoppingMigros', handleNumberInput(e.target.value))}
                        inputProps={{ inputMode: 'numeric', endAdornment: (<InputAdornment position='end'>%</InputAdornment>) }}
                        error={this.state.validated && (this.state.percShoppingMigrosErrorText.length !== 0 || this.state.percShoppingTotalErrorText.length !== 0)}
                        helperText={this.state.validated && (this.state.percShoppingMigrosErrorText.length !== 0 || this.state.percShoppingTotalErrorText.length !== 0) ? t('form_error_number') +
                            t('form_error_totalshopping') : ''}
                        autoFocus={true}
                    />,
                    <SurveyTextField
                        id='shopping-perc-coop'
                        value={values.percShoppingCoop}
                        label={"Coop " + t('profile_shoppingfrequency')}
                        placeholder={t('form_percent')}
                        onChange={(e) => handleChange('percShoppingCoop', handleNumberInput(e.target.value))}
                        inputProps={{ inputMode: 'numeric', endAdornment: (<InputAdornment position='end'>%</InputAdornment>) }}
                        error={this.state.validated && (this.state.percShoppingCoopErrorText.length !== 0 || this.state.percShoppingTotalErrorText.length !== 0)}
                        helperText={this.state.validated && (this.state.percShoppingCoopErrorText.length !== 0 || this.state.percShoppingTotalErrorText.length !== 0) ? t('form_error_number') +
                            t('form_error_totalshopping') : ''}
                    />,
                    <Typography
                        variant='h6'
                        align='center'
                        sx={{ mt: 2 }}
                    >
                        {t('survey_question_remembercard')}
                    </Typography>,
                    <SurveyTextField
                        id='shopping-use-migros'
                        label={"Migros " + t('profile_loyaltycardusage')}
                        value={values.usageMigros}
                        placeholder={t('form_percent')}
                        onChange={(e) => handleChange('usageMigros', handleNumberInput(e.target.value))}
                        inputProps={{ inputMode: 'numeric', endAdornment: (<InputAdornment position='end'>%</InputAdornment>) }}
                        error={this.state.validated && this.state.usageMigrosErrorText.length !== 0}
                        helperText={this.state.validated && this.state.usageMigrosErrorText.length !== 0 ? t('form_error_number') : ''}
                    />,
                    <SurveyTextField
                        id='shopping-use-coop'
                        label={"Coop " + t('profile_loyaltycardusage')}
                        value={values.usageCoop}
                        placeholder={t('form_percent')}
                        onChange={(e) => handleChange('usageCoop', handleNumberInput(e.target.value))}
                        inputProps={{ inputMode: 'numeric', endAdornment: (<InputAdornment position='end'>%</InputAdornment>) }}
                        error={this.state.validated && this.state.usageCoopErrorText.length !== 0}
                        helperText={this.state.validated && this.state.usageCoopErrorText.length !== 0 ? t('form_error_number') : ''}
                    />,
                    <SurveyButtonGroup back={() => { this.props.prevStep(); }} next={this.validateForm} />
                ]}
            />
        )
    }
}

export default withTranslation()(UserShopping);