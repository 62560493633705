import React, { useState } from 'react';
import { Avatar, Button, CssBaseline, TextField, Grid, Box, Typography, Container, ThemeProvider } from '@mui/material';
import { Link } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import colourTheme from '../../styles/ColourTheme';
import LanguageHeader from '../Language/LanguageHeader';
import { ErrorAlert, InfoAlert } from '../Alert/Alerts';
import { linkWithUnderline } from '../../styles/ComponentStylings';
import validateAuthFields from '../../utils/formValidator';
import { useAuth } from '../../services/Authentication/useAuth';
import { signUpService } from '../../services/Authentication/useAuth';
import { scraperService } from '../../services/Scraper/scraperService';

export default function SignIn() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    let auth = useAuth();

    const [fields, handleFieldChange] = useFormFields({
        email: '',
        password: '',
    });

    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [controlUserSigninError, setControlUserSigninError] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    const [emailNotRegistered, setEmailNotRegistered] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isUsedEmail = await signUpService.checkEmailExists(fields.email, true).catch(() => {
            setConnectionError(true);
        });
        if (isUsedEmail === false) {
            setEmailNotRegistered(true);
            return false;
        }

        if (validateAuthFields('email', fields.email)) {
            auth.signin(fields.email, fields.password)
                .then(async (user) => {
                    if (user === null || user === undefined) {
                        setOpenWarningDialog(true);
                    } else if (user === 'Control users cannot login') {
                        setControlUserSigninError(true);
                    } else {
                        if (user.completed_registration === false) navigate('/complete-registration');
                        else if (user.verified) {
                            if (user.bam_user === false) {
                                scraperService.scraperRequest('POST', '/user/update-migros');
                                scraperService.scraperRequest('POST', '/user/update-coop');
                                navigate(`/recommendations?initial=true`);
                            } else navigate('/recommendations');
                        } else {
                            auth.logout();
                            navigate('/email-needs-verification');
                        }
                    }
                })
                .catch(() => {
                    setConnectionError(true);
                });
        } else {
            setOpenWarningDialog(true);
        }
    };

    return (
        <>
            <ThemeProvider theme={colourTheme}>
                <LanguageHeader />
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 12,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: '#5472d3' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {t('signin')}
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                type="email"
                                label="E-Mail"
                                name="email"
                                autoComplete="email"
                                value={fields.email}
                                onChange={handleFieldChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('form_password')}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={fields.password}
                                onChange={handleFieldChange}
                            />
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                {t('signin')}
                            </Button>
                            <Button
                                color="info"
                                fullWidth
                                variant="contained"
                                sx={{ mb: 2 }}
                                onClick={() => {
                                    navigate('/signup');
                                }}
                            >
                                {t('signin_text_signup')}
                            </Button>
                            <Container maxWidth="lg">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={12}>
                                        <Link to="/reset-password" style={linkWithUnderline}>
                                            {t('signin_text_forgotpassword')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Box>

                    <InfoAlert
                        open={controlUserSigninError}
                        onClose={() => {
                            setControlUserSigninError(false);
                        }}
                        text={t('alert_controlgroup_password_reset_error')}
                    />
                    <ErrorAlert
                        open={openWarningDialog}
                        onClose={() => {
                            setOpenWarningDialog(false);
                        }}
                        text={t('alert_signinunauthorized')}
                    />
                    <ErrorAlert
                        open={emailNotRegistered}
                        onClose={() => {
                            setEmailNotRegistered(false);
                        }}
                        text={t('error_email_not_registered')}
                    />
                    <ErrorAlert
                        open={connectionError}
                        onClose={() => {
                            setConnectionError(false);
                        }}
                        text={t('error_connection')}
                    />
                </Container>
            </ThemeProvider>
        </>
    );
}

/**
 * custom hook for common stateful logic of all form related components
 * @param initialState takes the initial state of form field as an object and saves it as a state variable called fields
 */
function useFormFields(initialState) {
    const [fields, setValues] = useState(initialState);

    return [
        fields,
        function (event) {
            setValues({
                ...fields,
                [event.target.id]: event.target.value,
            });
        },
    ];
}
