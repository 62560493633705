import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import SurveyPage from '../../pages/SurveyPage';
import { SurveyTextField, SurveyButtonGroup } from '../../utils/commonComponents';
import { validateSurveyFields } from '../../utils/formValidator';


class FoodCategoryShopping extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validated: false,
            percFruitsErrorText: 'error',
            percVegetablesErrorText: 'error',
            percProteinFoodsErrorText: 'error',
            percProcessedFoodsErrorText: 'error',
            percCarbsErrorText: 'error',
            percOilsErrorText: 'error',
            percBeveragesErrorText: 'error',
            value: props.value
        }
    }

    validInput() {
        let percFruitsValidity = false;
        let percVegetablesValidity = false;
        let percProteinFoodsValidity = false;
        let percProcessedFoodsValidity = false;
        let percCarbsValidity = false;
        let percOilsValidity = false;
        let percBeveragesValidity = false;

        let percFruits = this.props.values.percFruits;
        let percVegetables = this.props.values.percVegetables;
        let percProteinFoods = this.props.values.percProteinFoods;
        let percProcessedFoods = this.props.values.percProcessedFoods;
        let percCarbs = this.props.values.percCarbs;
        let percOils = this.props.values.percOils;
        let percBeverages = this.props.values.percBeverages;

        if (!validateSurveyFields('percentage', percFruits)) {
            this.setState({ percFruitsErrorText: 'Invalid input!' });
            percFruitsValidity = false;
        } else {
            this.setState({ percFruitsErrorText: '' });
            percFruitsValidity = true;
        }

        if (!validateSurveyFields('percentage', percVegetables)) {
            this.setState({ percVegetablesErrorText: 'Invalid input!' });
            percVegetablesValidity = false;
        } else {
            this.setState({ percVegetablesErrorText: '' });
            percVegetablesValidity = true;
        }

        if (!validateSurveyFields('percentage', percProteinFoods)) {
            this.setState({ percProteinFoodsErrorText: 'Invalid input!' });
            percProteinFoodsValidity = false;
        } else {
            this.setState({ percProteinFoodsErrorText: '' });
            percProteinFoodsValidity = true;
        }

        if (!validateSurveyFields('percentage', percProcessedFoods)) {
            this.setState({ percProcessedFoodsErrorText: 'Invalid input!' });
            percProcessedFoodsValidity = false;
        } else {
            this.setState({ percProcessedFoodsErrorText: '' });
            percProcessedFoodsValidity = true;
        }

        if (!validateSurveyFields('percentage', percCarbs)) {
            this.setState({ percCarbsErrorText: 'Invalid input!' });
            percCarbsValidity = false;
        } else {
            this.setState({ percCarbsErrorText: '' });
            percCarbsValidity = true;
        }

        if (!validateSurveyFields('percentage', percOils)) {
            this.setState({ percOilsErrorText: 'Invalid input!' });
            percOilsValidity = false;
        } else {
            this.setState({ percOilsErrorText: '' });
            percOilsValidity = true;
        }

        if (!validateSurveyFields('percentage', percBeverages)) {
            this.setState({ percBeveragesErrorText: 'Invalid input!' });
            percBeveragesValidity = false;
        } else {
            this.setState({ percBeveragesErrorText: '' });
            percBeveragesValidity = true;
        }

        this.setState({ validated: true });
        return percFruitsValidity && percVegetablesValidity && percProteinFoodsValidity & percProcessedFoodsValidity && percCarbsValidity && percOilsValidity && percBeveragesValidity;
    }

    validateForm = (e) => {
        e.preventDefault();
        if (!this.validInput()) return;
        this.props.nextStep();
    }

    render() {
        const { values, handleChange, handleNumberInput, t } = this.props;
        return (
            <SurveyPage
                title={t('survey_question_foodcategoryshoppingatmigroscoop')}
                content={[
                    <SurveyTextField
                        id='fruits-purchase'
                        label={t('form_label_fruits') + ' (in %)'}
                        value={values.percFruits}
                        onChange={(e) => handleChange('percFruits', handleNumberInput(e.target.value))}
                        error={this.state.validated && this.state.percFruitsErrorText.length !== 0}
                        helperText={this.state.validated && this.state.percFruitsErrorText.length !== 0 ? t('form_error_foodcategory') : ''}
                        autoFocus={true}
                        inputProps={{ inputMode: 'numeric' }}
                    />,
                    <SurveyTextField
                        id='vegetables-purchase'
                        label={t('form_label_vegetables') + ' (in %)'}
                        value={values.percVegetables}
                        onChange={(e) => handleChange('percVegetables', handleNumberInput(e.target.value))}
                        error={this.state.validated && this.state.percVegetablesErrorText.length !== 0}
                        helperText={this.state.validated && this.state.percVegetablesErrorText.length !== 0 ? t('form_error_foodcategory') : ''}
                        inputProps={{ inputMode: 'numeric' }}
                    />,
                    <SurveyTextField
                        id='protein-food-purchase'
                        label={t('form_label_proteinfood') + ' (in %)'}
                        value={values.percProteinFoods}
                        onChange={(e) => handleChange('percProteinFoods', handleNumberInput(e.target.value))}
                        error={this.state.validated && this.state.percProteinFoodsErrorText.length !== 0}
                        helperText={this.state.validated && this.state.percProteinFoodsErrorText.length !== 0 ? t('form_error_foodcategory') : ''}
                        inputProps={{ inputMode: 'numeric' }}
                    />,
                    <SurveyTextField
                        id='processed-food-purchase'
                        label={t('form_label_processedfood') + ' (in %)'}
                        value={values.percProcessedFoods}
                        onChange={(e) => handleChange('percProcessedFoods', handleNumberInput(e.target.value))}
                        error={this.state.validated && this.state.percProcessedFoodsErrorText.length !== 0}
                        helperText={this.state.validated && this.state.percProcessedFoodsErrorText.length !== 0 ? t('form_error_foodcategory') : ''}
                        inputProps={{ inputMode: 'numeric' }}
                    />,
                    <SurveyTextField
                        id='carbs-purchase'
                        label={t('form_label_carbs') + ' (in %)'}
                        value={values.percCarbs}
                        onChange={(e) => handleChange('percCarbs', handleNumberInput(e.target.value))}
                        error={this.state.validated && this.state.percCarbsErrorText.length !== 0}
                        helperText={this.state.validated && this.state.percCarbsErrorText.length !== 0 ? t('form_error_foodcategory') : ''}
                        inputProps={{ inputMode: 'numeric' }}
                    />,
                    <SurveyTextField
                        id='oils-purchase'
                        label={t('form_label_oils') + ' (in %)'}
                        value={values.percOils}
                        onChange={(e) => handleChange('percOils', handleNumberInput(e.target.value))}
                        error={this.state.validated && this.state.percOilsErrorText.length !== 0}
                        helperText={this.state.validated && this.state.percOilsErrorText.length !== 0 ? t('form_error_foodcategory') : ''}
                        inputProps={{ inputMode: 'numeric' }}
                    />,
                    <SurveyTextField
                        id='beverages-purchase'
                        label={t('form_label_beverages') + ' (in %)'}
                        value={values.percBeverages}
                        onChange={(e) => handleChange('percBeverages', handleNumberInput(e.target.value))}
                        error={this.state.validated && this.state.percBeveragesErrorText.length !== 0}
                        helperText={this.state.validated && this.state.percBeveragesErrorText.length !== 0 ? t('form_error_foodcategory') : ''}
                        inputProps={{ inputMode: 'numeric' }}
                    />,
                    <SurveyButtonGroup back={() => { this.props.prevStep(); }} next={this.validateForm} />
                ]
                }
            />
        )
    }
}

export default withTranslation()(FoodCategoryShopping);