import { Link } from 'react-router-dom';
import { linkStyling } from '../styles/ComponentStylings';
import { ListItem, ListItemIcon, ListItemText, Typography, Button, ButtonGroup, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function Title(props) {
    return (
        <Typography
            component='h1'
            variant='h6'
            color='inherit'
            noWrap
            sx={props.sx || { flexGrow: 1, pl: 2.5, pb: 2 }}
        >
            {props.title}
        </Typography>
    )
}

// items for dashboard menu
export function DashboardNavItem(props) {
    const { t } = useTranslation();

    return (
        <Link to={props.link} style={linkStyling} color='black' >
            <ListItem button >
                <ListItemIcon>
                    {props.icon}
                </ListItemIcon>
                <ListItemText primary={t(props.text)} />
            </ListItem>
        </Link>
    );
}

export function SurveyTextField(props) {
    return <TextField
        margin='normal'
        value={props.value}
        required
        fullWidth
        id={props.id}
        label={props.label}
        placeholder={props.placeholder}
        name={props.id}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        type={props.type}
        onChange={props.onChange}
        error={props.error}
        helperText={props.helperText}
        inputProps={props.inputProps}
    />
}

// back and next button for survey pages
export function SurveyButtonGroup(props) {
    const { t } = useTranslation();

    return (
        <Grid container justifyContent='center'>
            <ButtonGroup fullwidth='true' variant='contained' color='inherit' size='large' aria-label='large button group' sx={{ mt: 3, mb: 2 }}>
                <Button onClick={props.back}>{t('button_back')}</Button>
                <Button onClick={props.next}>{t('button_next')}</Button>
            </ButtonGroup>
        </Grid>
    )
}

export function ProfileInfo(props) {
    return (
        <TextField
            fullWidth
            label={props.label}
            margin='dense'
            defaultValue={props.value}
            variant='filled'
            multiline
            InputProps={{ readOnly: true }}
        />
    )
}