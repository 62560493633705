import React from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    Divider,
    TextField,
    Typography,
    Grid,
    Box,
    InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import coop_logo from '../assets/coop_logo.png';
import migros_logo from '../assets/migros_logo.png';
import Deactivate2fa from '../components/SignUpSurvey/Deactivate2fa';
import { scraperService } from '../services/Scraper/scraperService';

export default function ReconnectLoyaltyCards() {
    const { t } = useTranslation();

    const [migrosEmail, setMigrosEmail] = React.useState('');
    const [migrosPassword, setMigrosPassword] = React.useState('');
    const [coopEmail, setCoopEmail] = React.useState('');
    const [coopPassword, setCoopPassword] = React.useState('');
    const [showMigrosPassword, setShowMigrosPassword] = React.useState(false);
    const [showCoopPassword, setShowCoopPassword] = React.useState(false);

    const [openMigrosDialog, setOpenMigrosDialog] = React.useState(false);
    const [openCoopDialog, setOpenCoopDialog] = React.useState(false);
    const [validatingMigrosCredentials, setValidatingMigrosCredentials] = React.useState(false);
    const [migrosCredentialsValidated, setMigrosCredentialsValidated] = React.useState(null);
    const [validatingCoopCredentials, setValidatingCoopCredentials] = React.useState(false);
    const [coopCredentialsValidated, setCoopCredentialsValidated] = React.useState(null);
    const [displayReconnectLoyaltyCards, setDisplayReconnectLoyaltyCards] = React.useState(false);

    const validateMigrosCredentials = async () => {
        setMigrosCredentialsValidated(null);
        setValidatingMigrosCredentials(true);
        try {
            const validated = await scraperService.scraperRequest(
                'GET',
                '/validate/migros-credentials',
                migrosEmail,
                migrosPassword,
                null,
                null
            );
            if (validated) {
                let status = await scraperService.updateScraperCredentials(migrosEmail, migrosPassword);
                if (status === 200) {
                    setMigrosCredentialsValidated(validated);
                    scraperService.scraperRequest('POST', '/user/update-migros');
                } else {
                    setMigrosCredentialsValidated(false);
                    alert(t('something_went_wrong'));
                }
            } else setMigrosCredentialsValidated(false);
        } catch (error) {
            alert(t('something_went_wrong'));
        }
        setValidatingMigrosCredentials(false);
    };

    const validateCoopCredentials = async () => {
        setCoopCredentialsValidated(null);
        setValidatingCoopCredentials(true);
        try {
            const validated = await scraperService.scraperRequest(
                'GET',
                '/validate/coop-credentials',
                null,
                null,
                coopEmail,
                coopPassword
            );
            if (validated) {
                let status = await scraperService.updateScraperCredentials(null, null, coopEmail, coopPassword);
                if (status === 200) {
                    setCoopCredentialsValidated(validated);
                    scraperService.scraperRequest('POST', '/user/update-coop');
                } else setCoopCredentialsValidated(false);
            } else {
                setCoopCredentialsValidated(false);
                alert(t('something_went_wrong'));
            }
        } catch (error) {
            alert(t('something_went_wrong'));
        }
        setValidatingCoopCredentials(false);
    };

    return (
        <Grid sx={{ mt: 2 }} alignContent={'center'} justifyContent={'center'} container>
            <Button
                sx={{ width: '95%', maxWidth: '300px', bgcolor: '#ebebeb', color: 'black', mx: 'auto' }}
                onClick={() => setDisplayReconnectLoyaltyCards(!displayReconnectLoyaltyCards)}
            >
                <Typography fontSize={15} align="center">
                    {t('reconnect_loyalty_cards')}
                </Typography>
            </Button>
            {displayReconnectLoyaltyCards && (
                <>
                    <Grid alignContent="center" justifyContent="center" container sx={{ mb: 10 }}>
                        <Grid
                            container
                            justifyContent="space-around"
                            spacing={2}
                            sx={{ pt: 2, pb: 2, maxWidth: '500px' }}
                        >
                            <Grid container direction="column" item xs={6} alignItems={'center'}>
                                <Button
                                    onClick={() => {
                                        setOpenMigrosDialog(true);
                                    }}
                                    sx={{
                                        position: 'relative',
                                        width: '100%',
                                        // height: '100%',
                                        paddingBottom: '100%',
                                        boxShadow: '0px 0px 5px 2px grey',
                                        minWidth: '50px', // Set a minimum width for the button
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}
                                >
                                    <img
                                        src={migros_logo}
                                        alt="Logo"
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: '100%',
                                            height: 'auto',
                                            maxWidth: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Button>

                                <Box width={32} height={32}>
                                    {validatingMigrosCredentials ? (
                                        <CircularProgress size={32} />
                                    ) : migrosCredentialsValidated ? (
                                        <CheckCircleIcon style={{ color: 'green', fontSize: '2rem' }} />
                                    ) : (
                                        migrosCredentialsValidated === false && (
                                            <ErrorIcon style={{ color: 'red', fontSize: '2rem' }} />
                                        )
                                    )}
                                </Box>
                                <Box width={150} height={50}>
                                    {migrosCredentialsValidated && (
                                        <Typography align="center" fontSize={13}>
                                            {t('migros_successfully_updated')}
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                            <Grid container direction="column" item xs={6} alignItems={'center'}>
                                <Button
                                    onClick={() => {
                                        setOpenCoopDialog(true);
                                    }}
                                    sx={{
                                        position: 'relative',
                                        width: '100%',
                                        // height: '100%',
                                        paddingBottom: '100%',
                                        boxShadow: '0px 0px 5px 2px grey',
                                        minWidth: '50px', // Set a minimum width for the button
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}
                                >
                                    <img
                                        src={coop_logo}
                                        alt="Logo"
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: '100%',
                                            height: 'auto',
                                            maxWidth: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Button>

                                {validatingCoopCredentials ? (
                                    <>
                                        <Box width={32} height={32}>
                                            <CircularProgress size={32} />
                                        </Box>
                                        <Typography fontWeight={600} fontSize={18} align="center">
                                            {t('validating_coop')}
                                        </Typography>
                                    </>
                                ) : coopCredentialsValidated ? (
                                    <Box width={32} height={32}>
                                        <CheckCircleIcon style={{ color: 'green', fontSize: '2rem' }} />
                                    </Box>
                                ) : (
                                    coopCredentialsValidated === false && (
                                        <Box width={32} height={32}>
                                            <ErrorIcon style={{ color: 'red', fontSize: '2rem' }} />
                                        </Box>
                                    )
                                )}
                                <Box width={150} height={50}>
                                    {coopCredentialsValidated && (
                                        <Typography align="center" fontSize={13}>
                                            {t('coop_successfully_updated')}
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Dialog open={openMigrosDialog} onClose={() => setOpenMigrosDialog(false)}>
                        <DialogContent
                            sx={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}
                        >
                            <img
                                src={migros_logo}
                                alt="Logo"
                                width="150"
                                height="150"
                                style={{
                                    padding: 10,
                                    marginBottom: 10,
                                    boxShadow: '0px 0px 5px 2px grey',
                                }}
                            />
                            <Deactivate2fa />
                            <Divider sx={{ width: '100%', my: 2 }} />
                            <TextField
                                margin="normal"
                                required
                                name="Migros Email"
                                label={t('Migros Email')}
                                type="Email"
                                id="Migros Email"
                                autoComplete="current-password"
                                value={migrosEmail}
                                onChange={(e) => setMigrosEmail(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                name="Migros password"
                                label={t('Migros password')}
                                id="Migros password"
                                autoComplete="current-password"
                                value={migrosPassword}
                                onChange={(e) => setMigrosPassword(e.target.value)}
                                type={showMigrosPassword ? 'text' : 'password'}
                                InputProps={{
                                    style: { width: '230px' },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowMigrosPassword(!showMigrosPassword)}>
                                                {showMigrosPassword ? (
                                                    <VisibilityOffIcon style={{ fontSize: 'small' }} />
                                                ) : (
                                                    <VisibilityIcon style={{ fontSize: 'small' }} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                onClick={() => {
                                    setOpenMigrosDialog(false);
                                    validateMigrosCredentials();
                                }}
                            >
                                {t('connect_migros')}
                            </Button>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={openCoopDialog} onClose={() => setOpenCoopDialog(false)}>
                        <DialogContent
                            sx={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}
                        >
                            <img
                                src={coop_logo}
                                alt="Logo"
                                width="150"
                                height="150"
                                style={{
                                    padding: 10,
                                    marginBottom: 10,
                                    boxShadow: '0px 0px 5px 2px grey',
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                name="Supercard Email"
                                label="Supercard Email"
                                type="Email"
                                id="Supercard Email"
                                autoComplete="current-password"
                                value={coopEmail}
                                onChange={(e) => setCoopEmail(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                name="Supercard password"
                                label={t('supercard_password')}
                                id="Supercard password"
                                autoComplete="current-password"
                                value={coopPassword}
                                onChange={(e) => setCoopPassword(e.target.value)}
                                type={showCoopPassword ? 'text' : 'password'}
                                InputProps={{
                                    style: { width: '230px' },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowCoopPassword(!showCoopPassword)}>
                                                {showCoopPassword ? (
                                                    <VisibilityOffIcon style={{ fontSize: 'small' }} />
                                                ) : (
                                                    <VisibilityIcon style={{ fontSize: 'small' }} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                onClick={() => {
                                    setOpenCoopDialog(false);
                                    validateCoopCredentials();
                                }}
                            >
                                {t('connect_coop')}
                            </Button>
                        </DialogContent>
                    </Dialog>
                </>
            )}
        </Grid>
    );
}
