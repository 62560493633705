import React, { Component } from 'react';
import { ThemeProvider, Typography, Grid, Box, CssBaseline, Avatar, Container } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SurveyLanguageHeader from '../components/Language/SurveyLanguageHeader';
import colourTheme from '../styles/ColourTheme';


// Layout page for all survey questions
export class SurveyPage extends Component {
    render() {
        const { title, content } = this.props;

        return (
            <ThemeProvider theme={colourTheme}>
                <SurveyLanguageHeader />
                <Container component='main' maxWidth='xs'>
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: '#5472d3' }}>
                            <HelpOutlineIcon />
                        </Avatar>
                        <Box component='form' noValidate sx={{ mt: 1 }}>
                            <Grid container spacing={1} alignItems='center' justifyContent='center'>
                                <Grid item xs={12} sx={{ pb: 2 }}>
                                    <Typography component='h3' variant='h6' align='center'>
                                        {title}
                                    </Typography>
                                </Grid>
                                <Grid container spacing={1}>
                                    {content.map((comp, index) => (
                                        <Grid item key={index} xs={12} >
                                            {comp}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider >
        );
    }
}

export default SurveyPage;