import React, { Component } from 'react'
import AllergiesTable from '../Tables/AllergiesTable';
import { withTranslation } from 'react-i18next';
import SurveyPage from '../../pages/SurveyPage';
import { SurveyButtonGroup } from '../../utils/commonComponents';


class AllergiesQuestion extends Component {
    constructor(props) {
        super(props)
        this.state = { value: props.value }
    }

    render() {
        const { values, handleChange, t } = this.props;

        return (
            <SurveyPage
                title={t('survey_question_allergies')}
                content={[
                    <AllergiesTable
                        handleChange={handleChange}
                        values={values}
                    />,
                    <SurveyButtonGroup back={() => { this.props.prevStep(); }} next={() => { this.props.nextStep(); }} />
                ]}
            />
        )
    }
}

export default withTranslation()(AllergiesQuestion);
