import { useEffect, useMemo, useState } from 'react';
import { Paper, Card, CardContent, Typography, Button } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import useSWR from 'swr';

import CircularLoading from '../Loading/CircularLoading';
import DashboardPage from '../../pages/DashboardPage';
import { userService } from '../../services/User/userService';
import DashboardHomePage from '../../pages/DasboardHomePage';

export default function NutriScoreGraph() {
    const { search } = useLocation();
    const initialLoad = useMemo(() => new URLSearchParams(search), [search]).get('initial');

    const { t } = useTranslation();
    const [displayGermanDisorders, setDisplayGermanDisorders] = useState(false);
    useEffect(() => {
        if (i18next.language === 'de' || i18next.language === 'de-DE') setDisplayGermanDisorders(true);
        else setDisplayGermanDisorders(false);
        toast(t('update_triggered'));
    }, [t]);

    let user = JSON.parse(localStorage.getItem('user'));
    let now = Math.round(new Date().valueOf() / 1000);

    const { data, error } = useSWR('/user/nutri-score-graph', (url) =>
        userService.getGraphData(url, user.external_id, now - 365 * 24 * 60 * 60, now)
    );

    let data_points = [];
    const labels = [];

    if (data) {
        if (data !== 'No data') {
            for (const i in data.basket_nutri_score.slice(0, 7)) {
                const date = new Date(data.basket_nutri_score[i].basket_timestamp * 1000);
                const month = date.getMonth() + 1;
                const day = date.getDate();
                labels.push(day + '/' + month);
                data_points.push(ofcom_conversion(data.basket_nutri_score[i].basket_ofcom));
            }
        }
    }

    const nutrientData = {
        labels: labels.reverse(),
        datasets: [
            {
                label: 'Basket Data',
                data: data_points.reverse(),
                backgroundColor: 'primary',
                borderColor: 'white',
                borderWidth: 2,
            },
        ],
    };

    if (error) {
        console.log('An error occured while fetching the basket data');
        return (
            <DashboardPage
                content={
                    <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                        <CardContent>
                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                {t('error_fetching_profile_data')}
                            </Typography>
                        </CardContent>
                    </Card>
                }
            />
        );
    }

    if (user.bam_user)
        return (
            <DashboardPage
                content={
                    <Paper
                        sx={{
                            mt: 2,
                            mb: 9,
                            display: 'grid',
                            height: 'auto',
                        }}
                    >
                        {!data ? (
                            <CircularLoading />
                        ) : user.bam_tokens_received ? (
                            nutrientData && nutrientData.datasets[0].data.length === 0 ? (
                                <>
                                    <Card
                                        sx={{
                                            bgcolor: '#17541b',
                                            m: 2,
                                        }}
                                    >
                                        <CardContent>
                                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                                {t('infotext_bam_tokens_received')}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card
                                        sx={{
                                            bgcolor: '#17a2b8',
                                            m: 2,
                                        }}
                                    >
                                        <CardContent>
                                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                                {t('contact_us')}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </>
                            ) : (
                                <DashboardHomePage
                                    data={data}
                                    displayGermanDisorders={displayGermanDisorders}
                                    nutrientData={nutrientData}
                                />
                            )
                        ) : !user.bam_tokens_received && user.register_time * 1000 + 3600 * 24 * 1000 < now * 1000 ? (
                            <>
                                <Card
                                    sx={{
                                        // bgcolor: '#f44336',
                                        m: 2,
                                    }}
                                >
                                    <CardContent sx={{ bgcolor: '#f44336', mb: 3 }}>
                                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                            {t('infotext_no_bam_account')}
                                        </Typography>
                                    </CardContent>
                                    <Button variant="contained" fullWidth href={user.bam_link}>
                                        LINK
                                    </Button>

                                    <CardContent sx={{ bgcolor: '#17a2b8', mt: 3 }}>
                                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                            {t('contact_us')}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </>
                        ) : (
                            <>
                                <Card
                                    sx={{
                                        bgcolor: '#17a2b8',
                                        m: 2,
                                    }}
                                >
                                    <CardContent>
                                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                            {t('infotext_accessingdata')}
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        bgcolor: '#17a2b8',
                                        m: 2,
                                    }}
                                >
                                    <CardContent>
                                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                            {t('contact_us')}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </>
                        )}
                    </Paper>
                }
            />
        );
    else
        return (
            <>
                <DashboardPage
                    content={
                        <Paper
                            sx={{
                                mt: 2,
                                mb: 2,
                                display: 'grid',
                                height: 'auto',
                            }}
                        >
                            {initialLoad && <Toaster />}
                            {!data ? (
                                <CircularLoading />
                            ) : nutrientData && nutrientData.datasets[0].data.length === 0 ? (
                                user.register_time * 1000 + 3600 * 5 * 1000 < now * 1000 ? (
                                    <Card
                                        sx={{
                                            bgcolor: '#f44336',
                                            m: 2,
                                        }}
                                    >
                                        <CardContent>
                                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                                {t('infotext_no_data_new')}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ) : (
                                    <Card
                                        sx={{
                                            bgcolor: '#17a2b8',
                                            m: 2,
                                        }}
                                    >
                                        <CardContent>
                                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                                {t('infotext_accessingdata_new')}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )
                            ) : (
                                <DashboardHomePage
                                    data={data}
                                    displayGermanDisorders={displayGermanDisorders}
                                    nutrientData={nutrientData}
                                />
                            )}
                        </Paper>
                    }
                />
            </>
        );
}

const ofcom_conversion = (ofcom_value) => {
    switch (true) {
        case ofcom_value < -15:
            return 0;
        case ofcom_value <= -1:
            return (-ofcom_value - 1) / 14 + 4;
        case ofcom_value <= 2:
            return (-ofcom_value + 2) / 3 + 3;
        case ofcom_value <= 10:
            return (-ofcom_value + 10) / 8 + 2;
        case ofcom_value <= 18:
            return (-ofcom_value + 18) / 8 + 1;
        case ofcom_value <= 40:
            return (-ofcom_value + 40) / 22 + 0;
        default:
            return 0;
    }
};
