import React, { Component } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import LocalizedDatePicker from '../DatePicker/LocalizedDatePicker';
import SurveyPage from '../../pages/SurveyPage';
import { withTranslation } from 'react-i18next';
import { validateSurveyFields } from '../../utils/formValidator';
import { ErrorAlert } from '../Alert/Alerts';
import { Navigate } from 'react-router-dom';

class DateOfBirthQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            birthdateErrorText: '',
            displayEligibilityAlert: false,
            value: props.value,
        };
        this.handleCloseIneligibility = this.handleCloseIneligibility.bind(this);
    }

    continue = (e) => {
        e.preventDefault();
        if (!validateSurveyFields('birthdate', this.props.values.birthdate)) {
            this.setState({ birthdateErrorText: 'Invalid input!' });
            this.setState({ displayEligibilityAlert: true });
            return;
        } else this.setState({ birthdateErrorText: '' });
        this.props.nextStep();
    };

    handleCloseIneligibility() {
        this.setState({ displayEligibilityAlert: false });
        this.setState({ navigateToHome: true });
    }

    render() {
        const { values, handleChange, completeRegistration, t } = this.props;
        const { navigateToHome } = this.state;

        return navigateToHome ? (
            <Navigate to="/" />
        ) : (
            <SurveyPage
                content={[
                    completeRegistration ? (
                        <Typography variant="h6" align="center">
                            {t('complete_registration')}
                        </Typography>
                    ) : (
                        <Typography variant="h6" align="center">
                            {t('survey_information')}
                        </Typography>
                    ),
                    <Grid container item alignItems="center" justifyContent="space-around" sx={{ pb: 1, mt: 2 }}>
                        <LocalizedDatePicker
                            id="datepicker"
                            label={t('form_label_birthdate')}
                            value={values.birthdate}
                            helperText={this.state.birthdateErrorText.length === 0 ? '' : t('form_error_birthdate')}
                            error={this.state.birthdateErrorText.length === 0 ? false : true}
                            onChange={(e) => {
                                handleChange('birthdate', e.getTime());
                            }}
                        />
                    </Grid>,
                    <ErrorAlert
                        open={this.state.displayEligibilityAlert}
                        onClose={this.handleCloseIneligibility}
                        text={t('alert_ineligibleuser')}
                    />,
                    <Grid container justifyContent="space-around" sx={{ pt: 2, pb: 2 }}>
                        <Button
                            variant="contained"
                            color="inherit"
                            size="large"
                            aria-label="large button group"
                            onClick={this.continue}
                        >
                            {t('button_next')}
                        </Button>
                    </Grid>,
                ]}
            />
        );
    }
}

export default withTranslation()(DateOfBirthQuestion);
