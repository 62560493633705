import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { Button, Menu, MenuItem, ThemeProvider } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { colourTheme } from "../../styles/ColourTheme";


const languageMap = {
    en: { label: "English", active: true },
    de: { label: "Deutsch", active: false }
};

const LanguageMenu = (props) => {
    const selected = localStorage.getItem('i18nextLng').substring(0, 2) || 'en';
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [showLabel, setShowLabel] = useState(window.matchMedia("(min-width: 421px)").matches);

    const handleClick = (e) => {
        setMenuAnchor(e.currentTarget);
    }

    const handleClose = () => {
        setMenuAnchor(null);
    }

    /** Checks media size in order to display (or not) the menu label for changing language */
    useEffect(() => {
        window
            .matchMedia("(min-width: 421px)")
            .addEventListener('change', e => setShowLabel(e.matches));
    }, []);

    return (
        <ThemeProvider theme={colourTheme}>
            <div>
                <Button color={props.color || "secondary"} startIcon={<LanguageIcon />} onClick={handleClick} >
                    {(showLabel && selected.length > 1) ? languageMap[selected].label : ''}  {/* only display label for non-mobile devices */}
                    <ArrowDropDown fontSize="small" />
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={menuAnchor}
                    open={!!menuAnchor}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {Object.keys(languageMap)?.map(item => (
                        <MenuItem key={item} onClick={() => {
                            i18next.changeLanguage(item);
                            handleClose();
                        }}>
                            {languageMap[item].label}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </ThemeProvider>
    );
};

export default LanguageMenu;