import { configData } from '../../config.js';

export const userService = {
    getUserVerificationStatus,
    getShoppingAnalysisData,
    getShoppingHistoryData,
    getRecommendationData,
    getBetterProductsData,
    getSurveyData,
    getGraphData,
    sendRejectRecommendation,
    getRejectedRecommendations,
    undoRejectRecommendation,
};

function getUserVerificationStatus() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${configData.API_URL}/user`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            if (data !== '') {
                const verificationStatus = data['verified'];
                return verificationStatus;
            }
            return false;
        })
        .catch((error) => console.log('Get user error: ', error));
}

function getShoppingAnalysisData(url, userid, analysisType) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(userid, analysisType),
    };
    return fetch(`${configData.API_URL}${url}`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            if (data !== '') {
                if (analysisType === 'Spending') {
                    return Object.entries(data).map(([key, value]) =>
                        createAnalysisData(
                            value.month_timestamp,
                            value.total_value,
                            value.category_analysis,
                            analysisType
                        )
                    );
                }

                if (analysisType === 'Nutrition') {
                    return Object.entries(data).map(([key, value]) =>
                        createAnalysisData(
                            value.month_timestamp,
                            value.total_value,
                            value.category_analysis,
                            analysisType
                        )
                    );
                }
            }
            return 'No data';
        })
        .catch((error) => {
            throw error;
        });
}

function getShoppingHistoryData(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${configData.API_URL}${url}`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            if (data === '') return 'No data';
            return data;
        })
        .catch((error) => {
            throw error;
        });
}

function getGraphData(url, userid, start_timestamp, end_timestamp) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(userid, start_timestamp, end_timestamp),
    };
    return fetch(`${configData.API_URL}${url}`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            if (data === '') return 'No data';
            return data;
        })
        .catch((error) => {
            throw error;
        });
}

// gets automated recommendations
function getRecommendationData(url) {
    const requestOptions = {
        method: 'GET',
        headers: productsAuthHeader(),
    };
    return fetch(`${configData.API_URL}${url}`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            if (data === '') return [];
            return data;
        })
        .catch((error) => {
            throw error;
        });
}

// gets better products data for a specific product gtin
function getBetterProductsData(productgtin) {
    const requestOptions = {
        method: 'GET',
        headers: productsAuthHeader(productgtin),
    };
    return fetch(`${configData.API_URL}/user/better-products`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error;
        });
}

// returns the user's survey data from sign up
function getSurveyData() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${configData.API_URL}/user/survey`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error;
        });
}

function sendRejectRecommendation(recommendationToReject) {
    let recBody = {
        nutrient: recommendationToReject.nutrient,
        dietcoach_minor_category: recommendationToReject.dietcoach_minor_category,
        recommendation_en: recommendationToReject.recommendation_en,
        recommendation_de: recommendationToReject.recommendation_de,
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(recBody),
    };
    return fetch(`${configData.API_URL}/insert-rejected-recommendations`, requestOptions)
        .then(handleRejectRecommendationResponse)
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
}

function getRejectedRecommendations(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${configData.API_URL}${url}`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            if (data === '') return [];
            return data;
        })
        .catch((error) => {
            throw error;
        });
}

function undoRejectRecommendation(rejectedRecommendationToDelete) {
    let recBody = {
        nutrient: rejectedRecommendationToDelete.nutrient,
        dietcoach_minor_category: rejectedRecommendationToDelete.dietcoach_minor_category,
        recommendation_en: rejectedRecommendationToDelete.recommendation_en,
        recommendation_de: rejectedRecommendationToDelete.recommendation_de,
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(recBody),
    };
    return fetch(`${configData.API_URL}/delete-rejected-recommendations`, requestOptions)
        .then(handleResponse)
        .then(() => true)
        .catch((error) => false);
}

/**
 * return authorization header with basic auth credentials
 */
function authHeader(userid = undefined, analysistype = undefined, history = undefined) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        if (userid !== undefined && analysistype !== undefined) {
            return {
                Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
                'Content-Type': 'application/json',
                Authentication: user.token,
                'Participant-Id': userid,
                'Analysis-Type': analysistype,
            };
        }
        if (userid !== undefined && analysistype === undefined && history) {
            return {
                Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
                'Content-Type': 'application/json',
                Authentication: user.token,
                'Participant-Id': userid,
                'Start-Timestamp': '1646769134',
                'End-Timestamp': '1662667878',
            };
        }
        return {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
            Authentication: user.token,
        };
    } else {
        return {};
    }
}

function productsAuthHeader(gtin = undefined) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        if (gtin !== undefined) {
            return {
                Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
                'Content-Type': 'application/json',
                Authentication: user.token,
                'Worst-Product-GTIN': gtin,
            };
        } else {
            return {
                Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
                'Content-Type': 'application/json',
                Authentication: user.token,
            };
        }
    } else {
        return {};
    }
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (response.ok) {
            if (response.status === 200) {
                return data;
            }
            return '';
            // TODO: check if any other status than 200 returns actual data
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    });
}

function handleRejectRecommendationResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok || response.status !== 201) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

// secondCol will either be 'price' for spending data or 'kcal' for nutrition data
function createAnalysisRowData(name, secondCol, percent) {
    return { name, secondCol, percent };
}

function createAnalysisData(unixDate, totalCost, analysis, type) {
    let date = new Date(unixDate * 1000);
    let total = totalCost;

    if (type === 'Spending') {
        const analysisData = Object.entries(analysis).map(([key, value]) =>
            createAnalysisRowData(value.category_name, value.category_total_value, value.category_percentage)
        );
        return { date, total, analysisData };
    } else {
        const analysisData = Object.entries(analysis).map(([key, value]) =>
            createAnalysisRowData(value.category_name, value.category_total_value, value.category_percentage)
        );
        return { date, total, analysisData };
    }
}
