import React, { Component } from 'react'
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import SurveyPage from '../../pages/SurveyPage';
import { SurveyTextField, SurveyButtonGroup } from '../../utils/commonComponents';
import { validateSurveyFields } from '../../utils/formValidator';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { ErrorAlert } from "../Alert/Alerts";


class OtherAppsQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            historyAppErrorText: '',
            currentotherAppErrorText: '',
            showTextfieldHistoryApp: this.props.values.historyUsingApps,
            showTextfieldCurrentApp: this.props.values.currentlyUsingOtherApps,
            value: props.value,
            missingChoiceError: false
        }
        this.options = [
            { value: true, label: i18next.t('yes') },
            { value: false, label: i18next.t('no') },
        ];
    }

    handleHistoryAppChange = (value) => {

        // check if textfield for input should be shown
        // when user selects yes to the answer
        this.setState({ showTextfieldHistoryApp: value });
        this.props.handleChange('historyUsingApps', value);
    }

    handleCurrentOtherAppsChange = (value) => {
        // var currentlyUsingOtherApps = e.value;
        this.setState({ showTextfieldCurrentApp: value });
        this.props.handleChange('currentlyUsingOtherApps', value);
    }

    validInput() {
        var invalidInput = 0;
        if (!validateSurveyFields('select', this.props.values.historyUsingApps)) {
            this.setState({ missingChoiceError: true });
            invalidInput += 1;
        }

        if (!validateSurveyFields('select', this.props.values.currentlyUsingOtherApps)) {
            this.setState({ missingChoiceError: true });
            invalidInput += 1;
        }

        if (this.state.showTextfieldHistoryApp && !validateSurveyFields('select', this.props.values.historyApps)) {
            this.setState({ historyAppErrorText: i18next.t('appErrorText') });
            invalidInput += 1;
        }

        if (this.state.showTextfieldCurrentApp && !validateSurveyFields('select', this.props.values.currentOtherApps)) {
            this.setState({ currentotherAppErrorText: i18next.t('appErrorText') });
            invalidInput += 1;
        }

        this.setState({ validated: true });
        if (invalidInput > 0) return false;
        return true;
    }


    // updateTextField() {
    //     if (this.props.values.historyUsingApps && !this.state.showTextfieldHistoryApp) {
    //         this.setState({ showTextfieldHistoryApp: true });
    //     }

    //     if (this.props.values.currentlyUsingOtherApps && !this.state.showTextfieldHistoryApp) {
    //         this.setState({ showTextfieldCurrentApp: true });
    //     }
    // }

    handleCloseError = () => {
        this.setState({ missingChoiceError: false });
    }

    validateForm = (e) => {
        e.preventDefault();

        if (!this.validInput()) return;
        this.props.nextStep();
    }


    render() {
        const { values, handleChange, t } = this.props;
        const { showTextfieldHistoryApp, showTextfieldCurrentApp } = this.state;

        return (
            <SurveyPage
                title={t('survey_question_otherappshistory')}
                content={[
                    <TableContainer component={Paper}>
                        <Table aria-label='custom pagination table'>
                            <TableBody>
                                {this.options.map(({ value, label }, index) => {
                                    return (
                                        <TableRow key={`historic-app-row-${index}}`} style={{ width: "100%" }}>
                                            <label style={{ cursor: "pointer" }}>
                                                <TableCell>
                                                    <input type='checkbox'
                                                        id={`historic-app-checkbox-${index}`}
                                                        name={value}
                                                        checked={values.historyUsingApps === value}
                                                        onChange={() => this.handleHistoryAppChange(value)}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ width: "100%" }}>{label}</TableCell>
                                            </label>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>,
                    <>
                        {showTextfieldHistoryApp ?
                            <SurveyTextField
                                value={values.historyApps}
                                id='historyapps'
                                label={t('form_appname')}
                                error={this.state.validated && this.state.historyAppErrorText.length !== 0}
                                onChange={(e) => handleChange('historyApps', e.target.value)}
                                helperText={this.state.validated && this.state.historyAppErrorText.length !== 0 ? this.state.historyAppErrorText : ''}
                                autoFocus={true}
                            /> : ''}
                    </>,
                    <Typography component='h1' variant='h6' align='center'>
                        {t('survey_question_otherappscurrently')}
                    </Typography>,
                    <TableContainer component={Paper}>
                        <Table aria-label='custom pagination table'>
                            <TableBody>
                                {this.options.map(({ value, label }, index) => {
                                    return (
                                        <TableRow key={`current-app-row-${index}}`} style={{ width: "100%" }}>
                                            <label style={{ cursor: "pointer" }}>
                                                <TableCell>
                                                    <input type='checkbox'
                                                        id={`current-app-checkbox-${index}`}
                                                        name={value}
                                                        checked={values.currentlyUsingOtherApps === value}
                                                        onChange={() => this.handleCurrentOtherAppsChange(value)}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ width: "100%" }}>{label}</TableCell>
                                            </label>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>,
                    <>
                        {showTextfieldCurrentApp ?
                            <SurveyTextField
                                value={values.currentOtherApps}
                                id='currentotherapps'
                                label={t('form_appname')}
                                error={this.state.validated && this.state.currentotherAppErrorText.length !== 0}
                                onChange={(e) => handleChange('currentOtherApps', e.target.value)}
                                helperText={this.state.validated && this.state.currentotherAppErrorText.length !== 0 ? this.state.currentotherAppErrorText : ''}
                                autoFocus={true}
                            /> : ''}
                    </>,
                    <ErrorAlert
                        open={this.state.missingChoiceError}
                        onClose={this.handleCloseError}
                        text={t('alert_missing_choice')}
                    />,
                    <SurveyButtonGroup back={() => { this.props.prevStep(); }} next={this.validateForm} />
                ]}
            />
        )
    }
}

export default withTranslation()(OtherAppsQuestion);