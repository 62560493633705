import Home from './pages/Home';
import Error404Page from './pages/Error404Page';
import SignIn from './components/SignIn/SignIn';
import ForgotPassword from './components/SignIn/ForgotPassword';
import MultiStepSignUpSurvey from './components/SignUpSurvey/MultiStepSignUpSurvey';
import NutriScoreGraph from './components/Dashboard/NutriScoreGraph';
import SignupRoute from './components/SignUpSurvey/SignupRoute';
import Recommendations from './components/Dashboard/Recommendations';
import Profile from './components/Dashboard/Profile';
import FAQ from './components/Dashboard/FAQ';
import Analysis from './components/Dashboard/Analysis';
import EmailVerifiedPage from './pages/EmailVerifiedPage';
import VerifyEmailFirstPage from './pages/VerifyEmailFirstPage';
import ChangePassword from './components/SignIn/ChangePassword';
import SettingsPage from './pages/SettingsPage';
import AccountVerifiedPage from './pages/AccountVerifiedPage';
import { useAuth } from './services/Authentication/useAuth';
import { Navigate } from 'react-router-dom';

// TODO: look into why refresh now doesn't work with protectedroutes

// checks if user is logged in and if so redirects to desired page
const ProtectedRoute = ({ children }) => {
    const auth = useAuth();
    if (!auth.user) return <Navigate to="error" replace />;
    return children;
};

const routes = [
    {
        path: '/',
        children: [
            { path: '/', element: <Home /> },
            {
                path: '/foodcoach',
                element: <Home completeRegistration={false} referrer={'FoodCoach'} />,
            },
            {
                path: '/sanitas',
                element: <Home completeRegistration={false} referrer={'Sanitas'} />,
            },
            {
                path: '/lista',
                element: <Home completeRegistration={false} referrer={'Lista'} />,
            },
            {
                path: '/swissre',
                element: <Home completeRegistration={false} referrer={'SwissRe'} />,
            },
            { path: '/signin', element: <SignIn /> },
            {
                path: '/signup',
                element: <SignupRoute completeRegistration={false} />,
            },
            {
                path: '/complete-registration',
                element: <MultiStepSignUpSurvey completeRegistration={true} />,
            },
            { path: '/email-verified', element: <EmailVerifiedPage /> },
            { path: '/account-verified', element: <AccountVerifiedPage /> },

            { path: '/email-needs-verification', element: <VerifyEmailFirstPage /> },
            {
                path: '/overview',
                element: (
                    <ProtectedRoute>
                        <NutriScoreGraph />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/recommendations',
                element: (
                    <ProtectedRoute>
                        <Recommendations />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/shopping-analysis',
                element: (
                    <ProtectedRoute>
                        <Analysis />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/profile',
                element: (
                    <ProtectedRoute>
                        <Profile />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/questions-and-answers',
                element: (
                    <ProtectedRoute>
                        <FAQ />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/settings',
                element: (
                    <ProtectedRoute>
                        <SettingsPage />
                    </ProtectedRoute>
                ),
            },
            { path: '/reset-password', element: <ForgotPassword /> },
            { path: '/change-password', element: <ChangePassword /> },
            { path: '*', element: <Error404Page /> },
        ],
    },
];

export default routes;
