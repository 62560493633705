import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { linkStyling } from '../styles/ComponentStylings';
import hsg_logo from '../assets/hsg_logo.png';
import inselspital_logo from '../assets/inselspital_logo.png';
import SurveyInfoPage from '../pages/SurveyInfoPage';

const Home = ({ referrer }) => {
    const { t } = useTranslation();
    return (
        <SurveyInfoPage
            title={t('survey_welcome')}
            content={[
                <>
                    <Typography component="h1" variant="body1" color="inherit" align="center" sx={{ pb: 1 }}>
                        <img src={hsg_logo} alt="Logo" width="50" height="50" style={{ marginRight: 20 }} />
                        <img src={inselspital_logo} alt="Logo" width="50" height="50" />
                    </Typography>
                    <Typography component="h1" variant="body1" color="inherit" align="center" sx={{ pb: 1 }}>
                        <Trans i18nKey="survey_welcometext_1" />
                    </Typography>
                    <Typography component="h1" variant="body1" color="inherit" align="center" sx={{ pb: 1, mt: 2 }}>
                        {t('survey_welcometext_2')}
                    </Typography>
                    <Grid
                        container
                        sx={{ mt: 2 }}
                        spacing={1}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={10}>
                            <Link to="/signup" style={linkStyling} state={{ referrer: referrer }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    aria-label="large button group"
                                    sx={{ width: '150px' }}
                                >
                                    {t('signup')}
                                </Button>
                            </Link>
                        </Grid>

                        <Grid item xs={10}>
                            <Link to="/signin" style={linkStyling}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    aria-label="large button group"
                                    sx={{ width: '150px', mb: 5 }}
                                >
                                    {t('signin')}
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </>,
            ]}
        />
    );
};

export default Home;
