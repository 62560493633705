import React, { Component } from 'react'
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import SurveyPage from '../../pages/SurveyPage';
import { SurveyButtonGroup } from '../../utils/commonComponents';
import { validateSurveyFields } from '../../utils/formValidator';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { ErrorAlert } from "../Alert/Alerts";


class ActivityLevelQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            missingChoiceError: false,
        }
        this.options = [
            { value: 'sittingLying', label: i18next.t('survey_select_useractivity_lying') },
            { value: 'exclusivelySedentary', label: i18next.t('survey_select_useractivity_exclusivelySedentary') },
            { value: 'sedentary', label: i18next.t('survey_select_useractivity_sedentary') },
            { value: 'goingStanding', label: i18next.t('survey_select_useractivity_standing') },
            { value: 'strenuous', label: i18next.t('survey_select_useractivity_strenuous') }
        ];
    }

    handleOptionChange = (option) => {
        this.props.handleChange('activityLevelAtWork', option);
    }

    handleCloseError = () => {
        this.setState({ missingChoiceError: false });
    }

    validateForm = (e) => {
        e.preventDefault();

        if (!validateSurveyFields('select', this.props.values.activityLevelAtWork)) {
            this.setState({ missingChoiceError: true });
            return;
        }

        this.props.nextStep();
    }

    render() {
        const { values, t } = this.props;

        return (
            <SurveyPage
                title={t('survey_question_useractivity')}
                content={[
                    <TableContainer component={Paper}>
                        <Table aria-label='custom pagination table'>
                            <TableBody>
                                {this.options.map(({ value, label }, index) => {
                                    return (
                                        <TableRow key={`activity-at-work-row-${index}}`} style={{ width: "100%" }}>
                                            <label style={{ cursor: "pointer" }}>
                                                <TableCell>
                                                    <input type='checkbox'
                                                        id={`activity-at-work-checkbox-${index}`}
                                                        name={value}
                                                        checked={values.activityLevelAtWork === value}
                                                        onChange={() => this.handleOptionChange(value)}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ width: "100%" }}>{label}</TableCell>
                                            </label>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>,
                    <ErrorAlert
                        open={this.state.missingChoiceError}
                        onClose={this.handleCloseError}
                        text={t('alert_missing_choice')}
                    />,
                    <SurveyButtonGroup back={() => { this.props.prevStep(); }} next={this.validateForm} />
                ]}
            />
        )
    }
}

export default withTranslation()(ActivityLevelQuestion);
