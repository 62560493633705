import MultiStepSignUpSurvey from './MultiStepSignUpSurvey';
import { useLocation } from 'react-router-dom';

const SignupRoute = ({ completeRegistration }) => {
    const location = useLocation();
    const referrer = location?.state?.referrer || null;

    return <MultiStepSignUpSurvey completeRegistration={completeRegistration} referrer={referrer} />;
};

export default SignupRoute;
