import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Paper,
    Card,
    Grid,
    List,
    Divider,
    ListItem,
    ListItemText,
    CardContent,
    Accordion,
    IconButton,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import DashboardPage from '../../pages/DashboardPage';
import { userService } from '../../services/User/userService';
import CircularLoading from '../Loading/CircularLoading';
import BetterProducts from '../Recommendations/BetterProducts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import useSWR from 'swr';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ErrorAlert } from '../Alert/Alerts';
import i18next from 'i18next';
import UndoIcon from '@mui/icons-material/Undo';
import InfoIcon from '@mui/icons-material/Info';

//TODO: optimize fetching better products (only on accordion click maybe?)
export default function Recommendations() {
    const { t } = useTranslation();

    // fetch data
    const { data: recommendations, error } = useSWR('/user/automatic-recommendations', (url) =>
        userService.getRecommendationData(url)
    );
    const { data: rejections } = useSWR('/get-rejected-recommendations', (url) =>
        userService.getRejectedRecommendations(url)
    );
    const [topRecommendations, setTopRecommendations] = useState([]);
    const [rejectedRecommendations, setRejectedRecommendations] = useState([]);

    const [displayRejectErrorAlert, setDisplayRejectErrorAlert] = useState(false);
    const [expanded, setExpanded] = React.useState([false, false, false]);

    useEffect(() => {
        if (recommendations) {
            if (recommendations.length === 0) {
                console.log('No recommendation data fetched');
            } else {
                const recs = [...recommendations];
                for (let i = 0; i < recs.length; i++) {
                    recs[i].rejected = false;
                }
                setTopRecommendations(recs);
            }
        }
        if (rejections) {
            if (rejections.length === 0) {
                console.log('No rejected recommendations');
            } else {
                setRejectedRecommendations(rejections);
            }
        }
    }, [recommendations, rejections]);

    const handleSendRejection = (recommendationToReject) => {
        setExpanded([false, false, false]);
        userService.sendRejectRecommendation(recommendationToReject).then((success) => {
            if (success) {
                // add to rejected recommendations list
                rejectedRecommendations.push(recommendationToReject);
                if (topRecommendations.includes(recommendationToReject)) {
                    let index = topRecommendations.indexOf(recommendationToReject);
                    let newTopRecommendations = [...topRecommendations];
                    // // remove from array
                    // // delete newTopRecommendations[index];
                    // newTopRecommendations.splice(index, 1);
                    // // newTopRecommendations[index] = {};
                    // setTopRecommendations(newTopRecommendations);
                    newTopRecommendations[index].rejected = true;
                    setTopRecommendations(newTopRecommendations);
                } else console.log('Recommendation not found in top recommendations');
            } else {
                setDisplayRejectErrorAlert(true);
            }
        });
    };

    const handleSendUndoRejection = (recommendationToUndoReject) => {
        // setExpanded([false, false, false]);
        let recToUndoRejection = rejectedRecommendations[recommendationToUndoReject];
        userService.undoRejectRecommendation(recToUndoRejection).then((success) => {
            if (success) {
                // remove from rejected list
                let index_rejected = rejectedRecommendations.indexOf(recToUndoRejection);
                const newRejectedRecommendations = rejectedRecommendations.filter((_, i) => i !== index_rejected);
                let index_original = topRecommendations.indexOf(recToUndoRejection);
                setRejectedRecommendations(newRejectedRecommendations);
                // add recommendation at original position
                let newTopRecommendations = [...topRecommendations];
                if (index_original !== -1) {
                    newTopRecommendations[index_original].rejected = false;
                    setTopRecommendations(newTopRecommendations);
                } else {
                    newTopRecommendations.push({ ...recToUndoRejection, rejected: false });
                }
            } else {
                setDisplayRejectErrorAlert(true);
            }
        });
    };

    var { displayGermanRecommendationText } = false;
    if (i18next.language === 'de' || i18next.language === 'de-DE') displayGermanRecommendationText = true;

    // Handle automatic recommendations data fetching error
    if (error) {
        return (
            <DashboardPage
                content={
                    <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                        <CardContent>
                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                {t('error_fetching_recommendations')}
                            </Typography>
                        </CardContent>
                    </Card>
                }
            />
        );
    }

    return (
        <DashboardPage
            content={
                !recommendations ? (
                    <CircularLoading />
                ) : (
                    <Paper
                        sx={{
                            mt: 2,
                            mb: 9,
                            display: 'grid',
                            height: 'auto',
                        }}
                    >
                        <Typography variant="h5" noWrap align="center" sx={{ mt: 2, mb: 2 }}>
                            {t('automatic_recommendations')}
                        </Typography>
                        <div
                            style={{
                                width: '80%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="h10"
                                noWrap
                                align="center"
                                sx={{ mt: 2, mb: 2, whiteSpace: 'pre-line' }}
                            >
                                {t('automatic_recommendations_explanation')}
                            </Typography>
                        </div>
                        {topRecommendations.length !== 0 ? (
                            <>
                                {topRecommendations
                                    .reduce(function (filtered, sug) {
                                        if (!sug.rejected) {
                                            filtered.push(sug);
                                        }
                                        return filtered;
                                    }, [])
                                    .slice(0, 3)
                                    .map(
                                        (suggestion, index) =>
                                            Object.keys(suggestion).length !== 0 && (
                                                <React.Fragment key={index}>
                                                    <Accordion expanded={expanded[index]}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="recommendations-content"
                                                            sx={{ bgcolor: '#b3e5fc' }}
                                                            onClick={() => {
                                                                let newExpanded = [...expanded];
                                                                newExpanded[index] = !newExpanded[index];
                                                                setExpanded(newExpanded);
                                                            }}
                                                        >
                                                            {suggestion.recommendation_en.includes('Increase') ? (
                                                                <NorthIcon fontSize="large" color="success" />
                                                            ) : (
                                                                <SouthIcon fontSize="large" color="alert" />
                                                            )}
                                                            <Typography variant="button">
                                                                {displayGermanRecommendationText
                                                                    ? suggestion.recommendation_de
                                                                    : suggestion.recommendation_en}
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails align={'center'}>
                                                            <Typography
                                                                sx={{
                                                                    bgcolor: '#97e8ad',
                                                                    padding: 1,
                                                                    flexDirection: 'column',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <InfoIcon fontSize="large" color="action" />
                                                                {displayGermanRecommendationText
                                                                    ? suggestion.explanation_de
                                                                    : suggestion.explanation_en}
                                                            </Typography>
                                                            <BetterProducts
                                                                gtin={suggestion.worst_product_gtin}
                                                                suggestion={suggestion}
                                                                key={suggestion.recommendation_en}
                                                            />
                                                            <Grid container justifyContent="center">
                                                                <Button
                                                                    variant="outlined"
                                                                    color="alert"
                                                                    startIcon={<DeleteForeverIcon />}
                                                                    onClick={() => handleSendRejection(suggestion)}
                                                                    sx={{ mt: 2 }}
                                                                >
                                                                    {t('reject_recommendation')}
                                                                </Button>
                                                            </Grid>
                                                        </AccordionDetails>
                                                        <ErrorAlert
                                                            open={displayRejectErrorAlert}
                                                            onClose={() => {
                                                                setDisplayRejectErrorAlert(false);
                                                            }}
                                                            text="There was an error with the recommendation rejection. Please try again."
                                                        />
                                                    </Accordion>
                                                </React.Fragment>
                                            )
                                    )}
                            </>
                        ) : (
                            <Card sx={{ bgcolor: '#17a2b8', m: 2 }}>
                                <CardContent>
                                    <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                        {t('infotext_accessingdata_recommendations')}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                        {rejectedRecommendations.length !== 0 && (
                            <>
                                <Typography variant="h6" noWrap align="center" sx={{ mt: 4, mb: 2 }}>
                                    {t('rejected_recommendations')}
                                </Typography>
                                <List>
                                    {rejectedRecommendations.map((rejectedRecommendation, index) => (
                                        <React.Fragment>
                                            <ListItem
                                                secondaryAction={
                                                    <IconButton
                                                        color="alert"
                                                        edge="end"
                                                        aria-label="undo-rejection"
                                                        onClick={() => handleSendUndoRejection(index)}
                                                    >
                                                        <UndoIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemText
                                                    primary={
                                                        displayGermanRecommendationText
                                                            ? rejectedRecommendation.recommendation_de
                                                            : rejectedRecommendation.recommendation_en
                                                    }
                                                />
                                            </ListItem>
                                            <Divider component="li" />
                                        </React.Fragment>
                                    ))}
                                </List>
                            </>
                        )}
                    </Paper>
                )
            }
        />
    );
}
