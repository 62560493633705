import React from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DashboardPage from '../../pages/DashboardPage';

export default function FAQ() {
    const { t } = useTranslation();
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <DashboardPage
            content={
                <Box sx={{ pt: 2, pb: 8, mb: 2 }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}
                        >
                            {t('Q&A_q1')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={styles.div}>{t('Q&A_a1_1')}</div>
                            <div style={styles.div}>{t('Q&A_a1_2')}</div>
                            <div style={styles.div}>{t('Q&A_a1_3')}</div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}
                        >
                            {t('Q&A_q2')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={styles.div}>{t('Q&A_a2_1')}</div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}
                        >
                            {t('Q&A_q3')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={styles.div}>{t('Q&A_a3_1')}</div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}
                        >
                            {t('Q&A_q4')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={styles.div}>{t('Q&A_a4_1')}</div>
                            <div style={styles.div}>{t('Q&A_a4_2')}</div>
                            <div style={styles.div}>{t('Q&A_a4_3')}</div>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}
                        >
                            {t('Q&A_q5')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={styles.div}>{t('Q&A_a5_1')}</div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}
                        >
                            {t('Q&A_q6')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={styles.div}>{t('Q&A_a6_1')}</div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}
                        >
                            {t('Q&A_q7')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={styles.div}>
                                {user && user.bam_user === true ? (
                                    t('Q&A_a7_1')
                                ) : (
                                    <div>
                                        {t('Q&A_a7_1_hsg')}
                                        <a href="/settings" >
                                            {t('settings')}
                                        </a>
                                        {t('Q&A_a7_2_hsg')}
                                    </div>
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            }
        />
    );
}

const styles = {
    div: {
        marginTop: 10,
    },
};
