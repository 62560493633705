import React, { Component } from 'react';
import i18next from 'i18next';
import { WarningAlert } from '../Alert/Alerts';
import { SurveyTextField, SurveyButtonGroup } from '../../utils/commonComponents';
import { validateSurveyFields } from '../../utils/formValidator';
import SurveyPage from '../../pages/SurveyPage';
import { withTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { ErrorAlert } from "../Alert/Alerts";


class DemographicQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            weightErrorText: 'error',
            heightErrorText: 'error',
            bmiErrorText: 'error',
            genderErrorText: '',
            missingChoiceError: false,
            openBMIInfoDialog: false,
            value: props.value,
        };
        this.options = [
            { value: 'Female', label: i18next.t('female') },
            { value: 'Male', label: i18next.t('male') },
            { value: 'Other', label: i18next.t('other') },
        ];
    }

    // method checks if weight and height make sense
    // BMI formula: kg/m^2
    validBMI(weight, height) {
        if (weight !== '' && height !== '') {
            var bmi = weight / Math.pow(height / 100, 2);
            this.props.values.bmi = bmi.toFixed(2);
            if (bmi >= 8 && bmi <= 200) {
                return true;
            }
            if (bmi > 200) {
                // if bmi is over 200, send info that they should check if that's ok, but they can continue
                this.setState({ openBMIInfoDialog: true });
                return true;
            }
        }
        return false;
    }

    handleCloseError = () => {
        this.setState({ missingChoiceError: false });
    }

    validInput() {
        let invalidCount = 0;

        if (!validateSurveyFields('weight', this.props.values.weight)) {
            this.setState({ weightErrorText: 'Invalid input!' });
            invalidCount += 1;
        } else this.setState({ weightErrorText: '' });

        if (!validateSurveyFields('height', this.props.values.height)) {
            this.setState({ heightErrorText: 'Invalid input!' });
            invalidCount += 1;
        } else this.setState({ heightErrorText: '' });

        if (!this.validBMI(this.props.values.weight, this.props.values.height)) {
            this.setState({ bmiErrorText: 'Invalid input!' });
            invalidCount += 1;
        } else {
            this.setState({ bmiErrorText: '' });
        }

        if (!validateSurveyFields('select', this.props.values.gender)) {
            this.setState({ genderErrorText: 'Invalid input!' });
            this.setState({ missingChoiceError: true });
            invalidCount += 1;
        }

        this.setState({ validated: true });
        if (invalidCount > 0) return false;
        return true;
    }

    handleGenderChange = (value) => {
        this.props.handleChange('gender', value);
    };

    handleCloseDialog(closeDialog) {
        this.setState({ [closeDialog]: false });
    }

    validateForm = (e) => {
        e.preventDefault();

        if (!this.validInput()) return;
        this.props.nextStep();
    };

    render() {
        const { values, handleChange, handleNumberInput, t } = this.props;

        return (
            <SurveyPage
                title={t('form_gender')}
                content={[
                        <TableContainer component={Paper}>
                            <Table aria-label='custom pagination table'>
                                <TableBody>
                                    {this.options.map(({ value, label }, index) => {
                                        return (
                                            <TableRow key={`gender-row-${index}}`} style={{ width: "100%" }}>
                                                <label style={{ cursor: "pointer" }}>
                                                    <TableCell>
                                                        <input type='checkbox'
                                                            id={`gender-checkbox-${index}`}
                                                            name={value}
                                                            checked={values.gender === value}
                                                            onChange={() => this.handleGenderChange(value)}
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ width: "100%" }}>{label}</TableCell>
                                                </label>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>,
                    <ErrorAlert
                        open={this.state.missingChoiceError}
                        onClose={this.handleCloseError}
                        text={t('alert_missing_choice')}
                    />,
                    <SurveyTextField
                        value={values.height}
                        id="height"
                        label={t('form_label_height')}
                        placeholder={t('form_label_height')}
                        onChange={(e) => handleChange('height', handleNumberInput(e.target.value))}
                        error={
                            this.state.validated &&
                            (this.state.heightErrorText.length === 0 && this.state.bmiErrorText.length === 0
                                ? false
                                : true)
                        }
                        helperText={
                            this.state.validated &&
                            (this.state.heightErrorText.length === 0 && this.state.bmiErrorText.length === 0
                                ? ''
                                : t('form_error_height'))
                        }
                        autoFocus={true}
                        inputProps={{ inputMode: 'numeric' }}
                    />,
                    <SurveyTextField
                        value={values.weight}
                        id="weight"
                        label={t('form_label_weight')}
                        placeholder={t('form_label_weight')}
                        onChange={(e) => handleChange('weight', handleNumberInput(e.target.value))}
                        error={
                            this.state.validated &&
                            (this.state.weightErrorText.length === 0 && this.state.bmiErrorText.length === 0
                                ? false
                                : true)
                        }
                        helperText={
                            this.state.validated &&
                            (this.state.weightErrorText.length === 0 && this.state.bmiErrorText.length === 0
                                ? ''
                                : t('form_error_weight'))
                        }
                        inputProps={{ inputMode: 'numeric' }}
                    />,

                    <WarningAlert
                        open={this.state.openBMIInfoDialog}
                        onClose={this.handleCloseDialog.bind(this, 'openBMIInfoDialog')}
                        text={t('alert_message_suspiciousbmi')}
                    />,
                    <SurveyButtonGroup
                        back={() => {
                            if (this.props.completeRegistration) this.props.prevStep(0.5);
                            else this.props.prevStep(2);
                        }}
                        next={this.validateForm}
                    />,
                ]}
            />
        );
    }
}

export default withTranslation()(DemographicQuestions);
