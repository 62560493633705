import * as React from 'react';
import { Box, BottomNavigation, BottomNavigationAction, CssBaseline, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HelpIcon from '@mui/icons-material/Help';

export default function Navigation() {
    const { t } = useTranslation();
    const location = useLocation();
    const pathname = location.pathname; // in case user visits the path directly
    const [value, setValue] = React.useState(pathname);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    }

    return (
        <Box>
            <CssBaseline />
            <Paper sx={{ position: 'fixed', bottom: 0, width: '100%' }} elevation={3}>
                <BottomNavigation value={value} onChange={handleChange} >
                    <BottomNavigationAction sx={{ minWidth: "60px" }} label={t("recommendations")} icon={<ReviewsIcon />} component={Link} to='/recommendations' value='/recommendations' />
                    <BottomNavigationAction sx={{ minWidth: "60px" }} label={t("overview")} icon={<HomeIcon />} component={Link} to='/overview' value='/overview' />
                    <BottomNavigationAction sx={{ minWidth: "60px" }} label={t("analysis")} icon={<ShoppingCartIcon />} component={Link} to='/shopping-analysis' value='/shopping-analysis' />
                    <BottomNavigationAction sx={{ minWidth: "60px" }} label={t("profile")} icon={<AccountBoxIcon />} component={Link} to='/profile' value='/profile' />
                    <BottomNavigationAction sx={{ minWidth: "60px" }} label={t("Q&A")} icon={<HelpIcon />} component={Link} to='/questions-and-answers' value='/questions-and-answers' />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}