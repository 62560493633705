import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Dialog from '@mui/material/Dialog';
import { t } from 'i18next';
import { Button } from '@mui/material';

// export function FeatureNotImplementedAlert(props) {
//     const { t } = useTranslation();
//     return (
//         <Dialog open={props.open} onClose={() => {}}>
//             <Alert severity="warning" onClose={props.onClose}>
//                 <AlertTitle>Ohoh!</AlertTitle>
//                 {t('alert_featurenotimplemented')}
//             </Alert>
//         </Dialog>
//     );
// }

export function ErrorAlert(props) {
    return (
        <Dialog open={props.open} onClose={() => {}}>
            <Alert severity="error" role="button" onClose={props.onClose}>
                <AlertTitle>{props.title}</AlertTitle>
                {props.text}
            </Alert>
        </Dialog>
    );
}

export function WarningAlert(props) {
    return (
        <Dialog open={props.open} onClose={() => {}}>
            <Alert severity="warning" color="warning" role="button" onClose={props.onClose}>
                {props.text}
                {props.content}
            </Alert>
        </Dialog>
    );
}

export function InfoAlert(props) {
    return (
        <Dialog open={props.open} onClose={() => {}}>
            <Alert severity="info" color="info" role="button" onClose={props.onClose}>
                {props.text}
                {props.refresh && (
                    <Button
                        style={{ color: 'rgba(0, 0, 255, 1)', backgroundColor: 'rgba(0, 0, 0, 0)' }}
                        onClick={() => props.reloadPage()}
                    >
                        {t('refresh')}
                    </Button>
                )}
            </Alert>
        </Dialog>
    );
}
