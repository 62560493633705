import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import SurveyPage from '../../pages/SurveyPage';
import { SurveyButtonGroup } from '../../utils/commonComponents';
import { validateSurveyFields } from '../../utils/formValidator';
import { Button, Grid } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { ErrorAlert } from "../Alert/Alerts";

class IncomeQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            missingChoiceError: false,
            value: props.value,
        };
        this.options = [
            { label: '<= 40000 CHF', value: '<= 40000 CHF' },
            { label: '40000 - 80000 CHF', value: '40000 - 80000 CHF' },
            { label: '80000 - 120000 CHF', value: '80000 - 120000 CHF' },
            { label: '120000 - 160000 CHF', value: '120000 - 160000 CHF' },
            { label: '160000 - 200000 CHF', value: '160000 - 200000 CHF' },
            { label: '> 200000 CHF', value: '> 200000 CHF' },
            { label: i18next.t('survey_select_prefernoanswer'), value: 'prefer not to answer' },
        ];
    }

    handleCloseError = () => {
        this.setState({ missingChoiceError: false });
    }

    continue = (e) => {
        e.preventDefault();

        if (!validateSurveyFields('select', this.props.values.householdIncome)) {
            this.setState({ missingChoiceError: true });
            return;
        } else this.setState({ missingChoiceError: false });

        this.props.nextStep();
    };

    handleIncomeChange = (value) => {
        this.props.handleChange('householdIncome', value);
    };

    render() {
        const { values, t } = this.props;

        return (
            <SurveyPage
                title={t('survey_question_householdIncome')}
                content={[
                    <TableContainer component={Paper}>
                        <Table aria-label='custom pagination table'>
                            <TableBody>
                                {this.options.map(({ value, label }, index) => {
                                    return (
                                        <TableRow key={`income-row-${index}}`} style={{ width: "100%" }}>
                                            <label style={{ cursor: "pointer" }}>
                                                <TableCell>
                                                    <input type='checkbox'
                                                        id={`income-checkbox-${index}`}
                                                        name={value}
                                                        checked={values.householdIncome === value}
                                                        onChange={() => this.handleIncomeChange(value)}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ width: "100%" }}>{label}</TableCell>
                                            </label>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>,
                    <ErrorAlert
                        open={this.state.missingChoiceError}
                        onClose={this.handleCloseError}
                        text={t('alert_missing_choice')}
                    />,
                    this.props.dataFetchingMethod === 'HSG' ? (
                        <Button onClick={this.continue} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            {t('button_complete_survey')}
                        </Button>
                    ) : (
                        <Grid container justifyContent="space-around" sx={{ pt: 2, pb: 2 }}>
                            <SurveyButtonGroup back={this.props.prevStep} next={this.continue} />
                        </Grid>
                    ),
                ]}
            />
        );
    }
}

export default withTranslation()(IncomeQuestion);
