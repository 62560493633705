import React from "react";
import { userService } from "../services/User/userService";
import { useTranslation } from "react-i18next";
import CircularLoading from "../components/Loading/CircularLoading";
import useSWR from "swr";
import AnalysisPage from "./AnalysisPage";

export default function NutritionAnalysis(props) {
    const { t } = useTranslation();

    const { data, error } = useSWR("/user/shopping-analysis/shopping", (url) =>
        userService.getShoppingAnalysisData(url.substring(0, url.lastIndexOf("/")), props.userid, "Nutrition")
    );

    return (
        data || error ? (
            <AnalysisPage
                data={data}
                error={error}
                analysisType='Nutrition'
                pageTitle={t('nutrition_analysis')}
                handleBack={props.handleBack}
            />
        ) : (
            <CircularLoading />
        )
    );
}
